import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

const CheckInAsset = ({ onHide, getUserList, addEditUser, userdataId }) => {

    const userId = localStorage.getItem("userId")
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [userInput, setUserInput] = useState("")
    const [permissions, setPermissions] = useState([])
    const [allRoles, setAllRoles] = useState([])

    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        first_name: Yup.mixed().required("This field is required."),
        last_name: Yup.mixed().required("This field is required."),
        email: Yup.mixed().required("This field is required."),
        password: addEditUser ? null : Yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Must be alphanumeric and at least 8 characters").required("This field is required."),
        cnic: Yup.mixed().required("This field is required."),
        gender: Yup.mixed().required("This field is required."),
        contact: Yup.mixed().required("This field is required."),
        designation: Yup.mixed().required("This field is required."),
        department: Yup.mixed().required("This field is required."),
        role: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            cnic: "",
            password: "",
            gender: "",
            designation: "",
            contact: "",
            department: "",
            role: ""
        },

        onSubmit: async (data) => {

            if (addEditUser === true) {

                let obj = {
                    first_name: formik.values.first_name,
                    last_name: formik.values.last_name,
                    email: formik.values.email,
                    cnic: formik.values.cnic,
                    contact: formik.values.contact,
                    gender: formik.values.gender,
                    designation: formik.values.designation,
                    department: formik.values.department,
                    role: formik.values.role,
                }

                setSaveBtnLoading(true);
                const response = await dispatch(handlePutRequest(obj, `/api/user/update/${userdataId}`, false, true));
                if (response?.status === 200) {
                    getUserList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                else if (response?.status === 403) {
                    window.localStorage.clear();
                    history.push("/")
                    toast.info("Please Login again")
                }
                getUserList();
                setSaveBtnLoading(false);
                onHide();
            }

            else {
                setSaveBtnLoading(true);

                let obj = {
                    first_name: formik.values.first_name,
                    last_name: formik.values.last_name,
                    email: formik.values.email,
                    cnic: formik.values.cnic,
                    contact: formik.values.contact,
                    gender: formik.values.gender,
                    password: formik.values.password,
                    designation: formik.values.designation,
                    department: formik.values.department,
                    role: formik.values.role,
                }

                const response = await dispatch(handlePostRequest(obj, "/api/user/add", false, true));
                if (response?.status === 200) {
                    getUserList();
                    setSaveBtnLoading(false);
                    onHide();
                }
                else if (response?.status === 403) {
                    window.localStorage.clear();
                    history.push("/")
                    toast.info("Please Login again")
                }
                getUserList();
                setSaveBtnLoading(false);
                onHide();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getUserById = async () => {

        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/user/getbyid/${userdataId}`, false));

        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("first_name", keyData?.first_name)
            formik.setFieldValue("last_name", keyData?.last_name)
            formik.setFieldValue("email", keyData?.email)
            formik.setFieldValue("gender", keyData?.gender)
            formik.setFieldValue("cnic", keyData?.cnic)
            formik.setFieldValue("designation", keyData?.designation)
            formik.setFieldValue("department", keyData?.department?._id)
            formik.setFieldValue("role", keyData?.role?._id)
            const contact = keyData?.contact;
            const formattedContact = contact ? contact.toString() : "";
            formik.setFieldValue("contact", formattedContact);
            setIsActive(false)
        }
    };

    useEffect(() => {
        if (userdataId !== undefined && userdataId !== null && addEditUser === true) {
            getUserById();
        }
    }, [userdataId]);


    const getAllRoles = async () => {

        setIsActive(true);
        const res = await dispatch(handleGetRequest("/api/role/getAll", false));
        if (res) {
            setAllRoles(res?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        //  getAllRoles()
    }, [])


    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/asset"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/checkinasset")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);

        }

    }, []);

    return (
        <>


            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}> Check In Asset</h4>
                            <h6 style={{ color: 'gray' }}> Add Details</h6>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> RFID Code </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Asset Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='Select Role'
                                                    id="role"
                                                    name="role"
                                                    options=''
                                                    optionLabel='role_Name'
                                                    optionValue='_id'
                                                    value={formik.values.role}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sub Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-8 md:col-8">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Charges </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Date </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText type='date' className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Time </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="date" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Quantity </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="first_name" name="first_name" placeholder='Enter First Name' value={formik.values.first_name} onChange={formik.handleChange} type="text" />
                                                {getFormErrorMessage("first_name")}
                                            </div>
                                        </div>
                                    </div>

                                    {permissions.includes(2) ?
                                        <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                            <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                                            <Button className="Save-Button ml-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default CheckInAsset