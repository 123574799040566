import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory } from "react-router-dom";
import photo from '../../assets/warehouse-assets/photo.png'
import video from '../../assets/warehouse-assets/video.png'
import video2 from '../../assets/warehouse-assets/video2.png'
import video3 from '../../assets/warehouse-assets/video3.png'
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { TreeSelect } from 'primereact/treeselect';

const AddEditInventory = () => {

    const location = useLocation();
    const { isEditable, rowData, isView } = location.state;
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedFiles2, setSelectedFiles2] = useState([])

    const [allCategories, setAllCategories] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allBrands, setAllBrands] = useState([])
    const [allVendors, setAllVendors] = useState([])
    const [allUnits, setAllUnits] = useState([])

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        inventory_id: Yup.mixed().required("This field is required."),
        category_id: Yup.mixed().required("This field is required."),
        // subCategory_id: Yup.mixed().required("This field is required."),
        inventory_name: Yup.mixed().required("This field is required."),
        brand_id: Yup.mixed().required("This field is required."),
        // description: Yup.mixed().required("This field is required."),
        unit: Yup.mixed().required("This field is required."),
        sku: Yup.mixed().required("This field is required."),
        vendor_id: Yup.mixed().required("This field is required."),
        threshold: Yup.mixed().required("This field is required."),
        notes: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            inventory_id: "",
            category_id: "",
            subCategory_id: "",
            inventory_name: "",
            brand_id: "",
            description: "",
            unit: "",
            sku: "",
            vendor_id: "",
            threshold: "",
            notes: "",
        },

        onSubmit: async (data) => {

            if (isEditable) {

                setSaveBtnLoading(true);

                let obj = {
                    p_id: rowData?.id,
                    p_inventory_id: formik.values.inventory_id,
                    p_name: formik.values.inventory_name,
                    p_description: formik.values.description,
                    p_category_id: formik.values.category_id,
                    p_sub_categoryid: formik.values.subCategory_id,
                    p_brand_id: formik.values.brand_id,
                    p_vendor_id: formik.values.vendor_id,
                    p_unit_of_measurement: formik.values.unit.toString(),
                    p_min_t_value: formik.values.threshold,
                    p_SKU: formik.values.sku,
                    p_notes: formik.values.notes,
                    attachments: [
                        {
                            fileName: "",
                            fileType: "",
                            base64Data: ""
                        }
                    ],
                    p_roletype_id: parseInt(roletypeid),
                    p_usertype_id: parseInt(usertype_id),
                    p_warehouse_id: parseInt(warehouseid),
                    p_edit_by: parseInt(user_id),
                }

                console.log('obj', obj)
                const response = await dispatch(handlePutRequest(obj, "/api/Inventory/UpdateInventory", true, true));
                if (response?.statusCode == 200) {
                    history.push('./inventory')
                    setSaveBtnLoading(false);
                }
            }

            else {
                setSaveBtnLoading(true);

                let obj = {
                    inventory_id: formik.values.inventory_id,
                    name: formik.values.inventory_name,
                    description: formik.values.description,
                    category_id: formik.values.category_id,
                    sub_categoryid: formik.values.subCategory_id,
                    brand_id: formik.values.brand_id,
                    vendor_id: formik.values.vendor_id,
                    unit_of_measurement: formik.values.unit,
                    min_t_value: parseInt(formik.values.threshold),
                    sku: formik.values.sku,
                    notes: formik.values.notes,
                    attachments: [
                        {
                            fileName: "",
                            fileType: "",
                            base64Data: ""
                        }
                    ],
                    roletype_id: parseInt(roletypeid),
                    usertype_id: parseInt(usertype_id),
                    warehouse_id: parseInt(warehouseid),
                    createdby: parseInt(user_id),
                }

                const response = await dispatch(handlePostRequest(obj, "/api/Inventory/AddInventory", true, true));
                console.log('response', response)
                if (response?.statusCode == 200) {
                    history.push('./inventory')
                    setSaveBtnLoading(false);
                }

                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get All Category List
    const getCategoryList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllCategorioesByWarehouse?warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setAllCategories(response);
        }
        setIsActive(false);
    };

    //Get All Category List
    const getSubCategoryList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetAllSubCategoriesByCatid?Category_id=${formik.values.category_id}&warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setAllSubCategories(response);
        }
        setIsActive(false);
    };

    const getAllBrands = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/getAllBrand?warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setAllBrands(response);
        }
        setIsActive(false);
    };

    const getAllVendors = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllVendor?warehouse_id=${warehouseid || 0}`, true));

        if (response) {
            setAllVendors(response);
        }
        setIsActive(false);
    };

    const getAllUnits = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Inventory/GetUnitOfMeasurement`, true));
        if (response) {
            setAllUnits(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getCategoryList()
        getAllBrands()
        getAllVendors()
        getAllUnits()
    }, [])

    useEffect(() => {
        if (formik.values.category_id) {
            getSubCategoryList()
        }
    }, [formik.values.category_id])

    //Get WH By Id
    const getInventoryById = async (rowData) => {
        console.log('rowData', rowData)
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Inventory/GetInventoryByid?id=${rowData?.id}`, true));
        console.log('response', response)
        const keyData = response[0]
        if (keyData) {
            formik.setFieldValue('inventory_id', keyData?.inventory_id)
            formik.setFieldValue('category_id', keyData?.category_id)
            formik.setFieldValue('subCategory_id', keyData?.subcategory_id)
            formik.setFieldValue('inventory_name', keyData?.name)
            formik.setFieldValue('brand_id', keyData?.brand_id)
            formik.setFieldValue('description', keyData?.description)
            formik.setFieldValue('unit', keyData?.unit_of_measurement)
            formik.setFieldValue('sku', keyData?.sku)
            formik.setFieldValue('vendor_id', keyData?.vendor_id)
            formik.setFieldValue('threshold', keyData?.min_t_value)
            formik.setFieldValue('notes', keyData?.notes)
        }
        setIsActive(false)
    };

    useEffect(() => {
        if (isEditable, rowData) {
            getInventoryById(rowData);
        }
    }, [isEditable, rowData]);


    //File Handling
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [
                    ...prevFiles,
                    { file_extension: file.type, base64: base64String, file_name: file.name }
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    const handleFileUpload2 = (event) => {
        const files = Array.from(event.files);
        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles2((prevFiles) => [
                    { file_extension: file.type, base64: base64String, file_name: file.name }
                ]);
            };
            reader.readAsDataURL(file);
            return file;
        });
    };

    //For Deleting File
    const handleCancelFile = (file) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    const handleCancelFile2 = (file) => {
        setSelectedFiles2((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    // Generate Inventory Id
    const handleGenerateInvId = () => {
        const newInvId = generateRandomInvId();
        formik.setFieldValue('inventory_id', newInvId);
    };

    const generateRandomInvId = () => {
        const prefix = "inv-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    const ResetForm = () => {
        formik.setFieldValue('inventory_id', '')
        formik.setFieldValue('category_id', '')
        formik.setFieldValue('subCategory_id', '')
        formik.setFieldValue('inventory_name', '')
        formik.setFieldValue('brand_id', '')
        formik.setFieldValue('description', '')
        formik.setFieldValue('unit', '')
        formik.setFieldValue('sku', '')
        formik.setFieldValue('vendor_id', '')
        formik.setFieldValue('threshold', '')
        formik.setFieldValue('notes', '')
    }

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };


    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable && isView ? 'View Inventory' : isEditable && isView === false ? 'Edit Inventory' : 'Add New Inventory'}</h4>
                            <h6 style={{ color: 'gray' }}>{isEditable && isView ? '' : isEditable && isView === false ? 'Edit Details' : 'Add Details'} </h6>
                        </div>

                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Inventory ID </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate Inventory Id'
                                                        id='inventory_id'
                                                        name="inventory_id"
                                                        value={formik.values.inventory_id}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        tooltip='Auto Generate Inventory Id'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateInvId}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>

                                                <span className='ml-2'>
                                                    {getFormErrorMessage("inventory_id")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Category--'
                                                    id="category_id"
                                                    name="category_id"
                                                    options={allCategories}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik.values.category_id}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("category_id")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sub Category </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <TreeSelect
                                                    placeholder='Select Sub Category'
                                                    id="subCategory_id"
                                                    name="subCategory_id"
                                                    filter
                                                    options={allSubCategories}
                                                    value={formik.values?.subCategory_id}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm text-sm"
                                                ></TreeSelect>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("subCategory_id")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Inventory Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="inventory_name" name="inventory_name" placeholder='Enter Inventory Name' value={formik.values.inventory_name} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("inventory_name")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Brand </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Brand--'
                                                    id="brand_id"
                                                    name="brand_id"
                                                    options={allBrands}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.brand_id}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("brand_id")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Description </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <InputText className="p-inputtext-sm" id="description" name="description" placeholder='Enter Description' value={formik.values.description} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("description")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Unit of Measurement </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Unit of Measurement--'
                                                    id="unit"
                                                    name="unit"
                                                    options={allUnits}
                                                    optionLabel='name'
                                                    optionValue='id'
                                                    value={formik.values.unit}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("unit")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Sku </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="sku" name="sku" placeholder='Enter Sku' value={formik.values.sku} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("sku")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Supplier/Vendor </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <Dropdown
                                                    placeholder='--Select Supplier/Vendor--'
                                                    id="vendor_id"
                                                    name="vendor_id"
                                                    options={allVendors}
                                                    optionLabel='obj_Name'
                                                    optionValue='obj_id'
                                                    value={formik.values.vendor_id}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm"
                                                    autoComplete="off"
                                                ></Dropdown>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("vendor_id")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Min Threshold Value </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" id="threshold" name="threshold" placeholder='Enter Min Threshold Value' value={formik.values.threshold} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("threshold")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Notes </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputTextarea rows={5} className="p-inputtext-sm" id="notes" name="notes" placeholder='Enter Notes' value={formik.values.notes} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("notes")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="grid">
                                        <div class="col-12 lg:col-8 md:col-8">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Product Images </label>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={photo}
                                                                    width="100px" height="100px"
                                                                    alt="Profile Image"
                                                                    className="ml-4 photo-bg"
                                                                />
                                                                <FileUpload
                                                                    auto
                                                                    mode="basic"
                                                                    chooseLabel="Choose File"
                                                                    className="p-mt-2"
                                                                    onSelect={handleFileUpload}
                                                                    accept="image/*"
                                                                />
                                                            </div>

                                                            <div className="flex flex-row ml-4">
                                                                {selectedFiles && selectedFiles.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles.length === 0 && selectedFiles.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles && selectedFiles.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "10px" }}>
                                                                                    <img
                                                                                        src={file?.base64}
                                                                                        alt={`Attachment ${index + 1}`}
                                                                                        style={{
                                                                                            maxWidth: "100px",
                                                                                            maxHeight: "100px",
                                                                                            border: "2px solid rgb(172 172 173)",
                                                                                            borderRadius: "10px"
                                                                                        }}
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Image'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Add Video </label>
                                                </div>
                                                <div className="flex flex-column mt-2">
                                                    <div className="mb-2 flex flex-column">
                                                        <div className='flex flex-row ml-2'>
                                                            <div className='flex flex-column'>
                                                                <img
                                                                    src={video}
                                                                    // width="100px" height="100px"
                                                                    alt="Video"
                                                                    className="ml-4 video-bg"
                                                                />
                                                                <FileUpload
                                                                    auto
                                                                    mode="basic"
                                                                    chooseLabel="Choose File"
                                                                    className="p-mt-2"
                                                                    onSelect={handleFileUpload2}
                                                                    accept="video/*"
                                                                />
                                                            </div>

                                                            <div className="flex flex-row ml-4">
                                                                {selectedFiles2 && selectedFiles2.length ?
                                                                    <div className='flex flex-row'>
                                                                        {selectedFiles2.length === 0 && selectedFiles2.length === 0 ? (
                                                                            <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                        ) : (
                                                                            selectedFiles2 && selectedFiles2.map((file, index) => (
                                                                                <span key={index} className="" style={{ position: "relative", marginLeft: "10px" }}>
                                                                                    <video
                                                                                        src={file?.base64}
                                                                                        alt='Video'
                                                                                        style={{
                                                                                            maxWidth: '130px',
                                                                                            maxHeight: '130px',
                                                                                            border: '2px solid rgb(172 172 173)',
                                                                                            borderRadius: '10px'
                                                                                        }}
                                                                                        controls
                                                                                    />
                                                                                    <Button
                                                                                        tooltip='Remove Video'
                                                                                        tooltipOptions={{ position: "top" }}
                                                                                        type='button'
                                                                                        icon="pi pi-times"
                                                                                        className='remove-file-button'
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: '0px',
                                                                                            right: '0px',
                                                                                            zIndex: 1,
                                                                                            fontSize: '10px'
                                                                                        }}
                                                                                        onClick={() => handleCancelFile2(file)}
                                                                                    />
                                                                                </span>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                    :
                                                                    <div className='mt-4 flex'>
                                                                        <p style={{ fontSize: "14px", fontWeight: "600", fontStyle: "italic", color: "gray" }}></p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {isView ?
                                        null
                                        :
                                        <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                            <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                            <Button className="Save-Button ml-2" label={isEditable ? 'Update' : 'Add'} loading={saveBtnLoading} type="submit" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default AddEditInventory