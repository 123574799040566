import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import './Dashboard.scss'
import { Chart } from 'primereact/chart';
import { Dropdown } from "primereact/dropdown";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Calendar } from 'primereact/calendar';
import { DataTable } from "primereact/datatable";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { Column } from "jspdf-autotable";
import Inventory from "../../assets/imgs/inventory.svg"
import Assets from "../../assets/imgs/Assets.svg"
import Registered from "../../assets/imgs/Registered.svg"

const Dashboard = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartOptions2, setChartOptions2] = useState({});
    const [chartData3, setChartData3] = useState({});
    const [chartOptions3, setChartOptions3] = useState({});
    const [isActive, setIsActive] = useState(false);
    const chart3Ref = useRef();



    const getChart1Data = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ["Warehouse 1", "Warehouse 1", "Warehouse 1", "Warehouse 1", "Warehouse 1"],
            datasets: [
                {
                    label: 'Users for Consumables',
                    backgroundColor: 'rgba(106, 78, 209, 0.35)',
                    borderColor: '#2d8eff',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    barThickness: 50,
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor,
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)"
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    };


    const getChart2Data = () => {

        const data = {
            labels: ['Borrowed', 'Returned', "Expiry Inventory"],
            datasets: [
                {
                    data: [540, 325, 200],
                    backgroundColor: [
                        'rgba(106, 78, 209, 0.35)',
                        'rgba(191, 174, 252, 1)',
                        'rgba(117, 89, 221, 0.6)',
                    ],
                    hoverBackgroundColor: [
                        'rgba(141, 122, 213, 1)',
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData2(data);
        setChartOptions2(options);
    };

    // const getChart3Data = () => {

    //     const data = {
    //         labels: ['Active Users', 'Inactive Users',],
    //         datasets: [
    //             {
    //                 data: [300, 50],
    //                 backgroundColor: [
    //                     '#58f3fd',
    //                     '#f40505'
    //                 ],
    //                 hoverBackgroundColor: [
    //                     '#58f3fd',
    //                     '#f40505'
    //                 ]
    //             }
    //         ]
    //     };
    //     const options = {
    //         cutout: '60%'
    //     };

    //     setChartData3(data);
    //     setChartOptions3(options);
    // };


    const getChart3Data = () => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(212, 201, 255, 1)',
                    // borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'My Second dataset',
                    backgroundColor: 'rgba(106, 78, 209, 0.7)',
                    // borderColor: documentStyle.getPropertyValue('--pink-500'),
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)",
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: "rgba(40, 75, 117, 1)"
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };
        setChartData3(data);
        setChartOptions3(options);
    }


    useEffect(() => {
        getChart1Data()
        getChart2Data()
        getChart3Data()
    }, [])

    const header = () => (
        <>
            <div className="flex  justify-content-between align-items-center  ">
                <h4>Reporting</h4>
                <div className="flex justify-content-end align-items-end  w-max-content">
                    <div>
                        <Button className="mr-4" label="Download" icon='pi pi-download' />
                    </div>
                    <div className="w-8rem mr-4">
                        <label>From Date</label>
                        <Calendar className="" />
                    </div>
                    <div className="w-8rem">
                        <label id="name">To Date</label>
                        <Calendar className="" name="toDate" />
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>

            <div className="dashboarsd-padding">
                {/* Cards 1 */}
                <div className="grid ">
                    <div className="col-12 lg:col-4 md:col-4">
                        <div className="card p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={Inventory} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 ">Total Inventory</h5>
                                    <h2 className="font-bold mb-0">4000</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4 h-full">
                        <div className="card p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={Assets} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 ">Total Assets</h5>
                                    <h2 className="font-bold mb-0">4000</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <div className="card p-5">
                            <div>
                                <div className="h-3rem w-6rem" >
                                    <img className="h-full" src={Registered} />
                                </div>
                                <div className="flex justify-content-between align-items-end">
                                    <h5 className="card-text mt-4 mb-1 ">Total Registered Users</h5>
                                    <h2 className="font-bold mb-0">4000</h2>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="grid mt-4">
                    {/* Chart  1 */}
                    <div className="col-12 lg:col-7 md:col-7">
                        <div className="card p-3">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="p-2" style={{ color: "#284b75", fontWeight: '600' }}>Users for Consumables</div>
                                <div className="flex flex-column xl:flex-row justify-content-end xl:align-items-center w-max-content">
                                    <div className="w-8rem my-3 xl:my-0 xl:mr-4">
                                        <label>From Date</label>
                                        <Calendar className="" />
                                    </div>
                                    <div className="w-8rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" name="toDate" />
                                    </div>
                                </div>
                            </div>
                            <Chart type="bar" className='mt-5' data={chartData} options={chartOptions} />
                        </div>
                    </div>
                    {/* Chart 2 */}
                    <div className="col-12 lg:col-5 md:col-5">
                        <div className="card p-3">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="p-2" style={{ color: "#284b75", fontWeight: '600' }}>Asset Details</div>
                                <div className="flex flex-column xl:flex-row justify-content-end xl:align-items-center w-max-content">
                                    <div className="w-8rem my-3 xl:my-0 xl:mr-4 ">
                                        <label>From Date</label>
                                        <Calendar className="text-xs" />
                                    </div>
                                    <div className="w-8rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" name="toDate" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-content-center">
                                <Chart type="pie" data={chartData2} options={chartOptions2} className="chart2" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid mt-3">
                    {/* Chart 3 */}
                    {/* <div className="col-12 lg:col-7 md:col-7">
                        <div className="card p-3 chart3-responsive">
                            <div className="chart4-left">
                                <div className="p-2 ml-3" style={{ color: "black", fontWeight: '600', fontSize: "16px" }}> User Details</div>
                            </div>
                            <div className=" pr-3 pl-3 chart3-responsive2">
                                <div className="flex flex-column chart4-left-center mt-8 ml-3">
                                    <span style={{ color: "gray", fontSize: "18px" }}><span className="pi pi-circle mr-2" style={{ color: '#f40505' }}> </span>Inactive Users 25</span>
                                    <span style={{ color: "gray", fontSize: "18px" }} className="mt-3"> <span className="pi pi-circle" style={{ color: '#58f3fd' }}> </span> Active Users 75</span>
                                    <span style={{ color: "gray", fontSize: "16px" }} className="mt-6"> TOTAL </span>
                                    <span style={{ color: "black", fontSize: "3rem" }} className="mt-1"> 100 </span>
                                </div>
                                <div>
                                    <Chart type="doughnut" data={chartData3} options={chartOptions3} className="p-2 mb-6 chart3" />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-12 lg:col-7 md:col-7">
                        <div className="card p-3 h-full">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="p-2" style={{ color: "#284b75", fontWeight: '600' }}>Top Items</div>
                                <div className="flex flex-column xl:flex-row justify-content-end xl:align-items-center w-max-content">
                                    <div className="w-8rem my-3 xl:my-0 xl:mr-4">
                                        <label>From Date</label>
                                        <Calendar className="text-xs" />
                                    </div>
                                    <div className="w-8rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" name="toDate" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Chart type="bar" ref={chart3Ref} data={chartData3} options={chartOptions3} />
                            </div>

                        </div>
                    </div>


                    {/* Chart 4 */}
                    <div className="col-12 lg:col-5 md:col-5 ">
                        <div className="card p-3 h-full">
                            <div className="flex flex-column xl:flex-row justify-content-between xl:align-items-center  ">
                                <div className="p-2" style={{ color: "#284b75", fontWeight: '600' }}>Top Items</div>
                                <div className="flex flex-column xl:flex-row justify-content-end xl:align-items-center w-max-content">
                                    <div className="w-8rem my-3 xl:my-0 xl:mr-4">
                                        <label>From Date</label>
                                        <Calendar className="text-xs" />
                                    </div>
                                    <div className="w-8rem">
                                        <label id="name">To Date</label>
                                        <Calendar className="" name="toDate" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="flex justify-content-center">
                                <div className="chart4 mt-4">
                                    <CircularProgressbar
                                        styles={{
                                            path: {
                                                stroke: 'rgba(141, 122, 213, 1)',
                                            },
                                            text: {
                                                fill: "rgba(141, 122, 213, 1)"
                                            }
                                        }} value={66} text={`${66}%`} />
                                </div>
                            </div>
                            <div className="flex justify-content-between">
                                {/* <span className="flex flex-column">
                                    <span>
                                        Yet to Dispatched 75%
                                    </span>
                                    <span>
                                        Dispatched 25%
                                    </span>
                                </span> */}
                                <span className="flex flex-column" style={{ marginTop: "-16px" }}>
                                    <span style={{ fontSize: "1.5rem" }}>
                                        Total
                                    </span>
                                    <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                                        100
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <LoadingOverlay
                    active={isActive}
                    spinner={
                        <Triangle
                            height="120"
                            width="120"
                            color="#755fc8"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    }
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        value={""}
                        header={header}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40]}
                    >
                        <Column header="VendorName" />
                        <Column header="Contact No." />
                        <Column header="Address" />
                        <Column header="Total Inventory" />
                        <Column header="Total Asset" />
                        <Column header="Company Name" />
                        <Column header="Action" />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default Dashboard