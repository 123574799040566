import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { handlePutRequest } from "../../services/PutTemplate";
import { Tag } from "primereact/tag";

const ManageVendor = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [isActive, setIsActive] = useState(false)
    const [allVendor, setAllVendors] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'View',
                command: () => handleViewVendor(rowData),
            });
        } else {
            items.push(
                {
                    label: 'Edit',
                    command: () => handleEditVendor(rowData),
                },
                {
                    label: rowData?.status == 1 ? 'Deactivate' : 'Activate',
                    command: () => confirm(rowData),
                }
            );
        }

        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };


    const confirm = (rowData) => {
        confirmDialog({
            message: rowData?.status == 1 ? 'Are you sure you want to deactivate this Vendor?' : 'Are you sure you want to Activate this Vendor?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                if (rowData?.status == 1) {
                    handleDeactivateWH(rowData);
                } else {
                    handleActivateWH(rowData);
                }
            },
            reject: () => {
                return null;
            },
        });
    };

    const handleDeactivateWH = async (rowData) => {

        const obj = {
            obj_id: rowData?.obj_id,
            name: rowData?.obj_Name,
            description: rowData?.obj_Description,
            warehouse_id: rowData?.warehouse_id,
            address: rowData?.address,
            contact_no: rowData?.contact_no,
            email: rowData?.email,
            company: rowData?.company,
            isActive: 0
        }

        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateVendor", true, true));
        if (response?.statusCode == 200) {
            await getVendorList();
        }
        setIsActive(false);
    }

    const handleActivateWH = async (rowData) => {

        const obj = {
            obj_id: rowData?.obj_id,
            name: rowData?.obj_Name,
            description: rowData?.obj_Description,
            warehouse_id: rowData?.warehouse_id,
            address: rowData?.address,
            contact_no: rowData?.contact_no,
            email: rowData?.email,
            company: rowData?.company,
            isActive: 1
        }

        setIsActive(true);
        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateVendor`, true, true));
        if (response?.statusCode == 200) {
            await getVendorList();
        }
        setIsActive(false);
    }

    const handleEditVendor = (rowData) => {
        const view = false;
        const item = true;
        history.push({
            pathname: './addeditvendor',
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view
            }
        });
    };

    const handleViewVendor = (rowData) => {
        const item = true;
        const view = true;
        history.push({
            pathname: './addeditvendor',
            state: {
                isEditable: item,
                isView: view,
                rowData: rowData,
            }
        });
    };

    //Get Vendor List
    const getVendorList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Setups/GetAllVendor?warehouse_id=${warehouseid || 0}`, true));
        if (response) {
            setAllVendors(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getVendorList()
    }, [])

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/Setups"));
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/vendor")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

    }, []);

    return (
        <>

            <div className="flex flex-row custom-alignment justify-content-end">
                <span className="p-input-icon-left mr-3 resp-margin">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        className="search-input"
                    />
                </span>

                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                    null
                    :
                    permissions.includes(2) ?
                        <div className="">
                            <Button
                                label="Add New"
                                className="Add__New-Button ml-3 mt-1"
                                icon="pi pi-plus"
                                onClick={() => {
                                    const item = false;
                                    history.push({
                                        pathname: './addeditvendor',
                                        state: {
                                            isEditable: item,
                                        }
                                    });
                                }}
                            />
                        </div>
                        :
                        null
                }

            </div>
            <div className="card mt-3">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "email",
                            "obj_Name",
                            "company",
                            "contact_no",
                            "location",
                            "address",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allVendor}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column sortable field='obj_Name' body={(rowData) => rowData?.obj_Name || 'N/A'} header="Vendor Name"></Column>
                        <Column sortable field='email' body={(rowData) => rowData?.email || 'N/A'} header="Email"></Column>
                        <Column sortable field='contact_no' body={(rowData) => rowData?.contact_no || 'N/A'} header="Contact No."></Column>
                        <Column sortable field='company' body={(rowData) => rowData?.company || 'N/A'} header="Company"></Column>
                        <Column
                            sortable
                            field='obj_Description'
                            body={(rowData) => (
                                <>
                                    <div style={{ whiteSpace: 'pre-wrap', justifyContent:'start' }}>
                                        {rowData?.obj_Description?.replace(/(.{25})/g, '$1\n') || 'N/A'}
                                    </div>
                                </>
                            )}

                            header="Description"></Column>
                        <Column
                            field="address"
                            sortable
                            body={(rowData) => (
                                <div>
                                    {<div style={{ whiteSpace: 'pre-wrap' }}>
                                        {rowData?.address?.replace(/(.{25})/g, '$1\n') || 'N/A'}
                                    </div>}
                                </div>
                            )}
                            header="Address"
                        ></Column>
                        <Column
                            sortable
                            field="status"
                            body={(rowData) => (
                                <Tag severity={rowData?.status == 1 ? "success" : "danger"} value={rowData?.status == 1 ? 'Acitve' : 'Inactive'}></Tag>
                            )}
                            header="Status"
                        />
                        <Column sortable field='createddatetime' body={(rowData) => rowData?.createddatetime || 'N/A'} header="Date & Time"></Column>
                        {permissions.includes(1) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null
                        }
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageVendor