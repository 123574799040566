import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/warehouse-assets/colored/edit.png"
import deleteIcon from "../../assets/warehouse-assets/colored/dlet.png"
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Reporting = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [userdataId, setUserDataId] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        return (
            <div className="">
                <Button className='edit-btn ml-3' onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className='edit-btn mr-3' onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Edit" />
                </Button>
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this User ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteUser(rowData),
            reject: null
        });
    }

    const handleDeleteUser = async (rowData) => {

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            await getUserList()
        }

        else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/")
            toast.info("Please Login again")
        }

        setIsActive(false);
    }

    const nameTemplate = (rowData) => {

        const result = `${rowData.first_name} ${rowData?.last_name}`
        return (
            <>
                {result}
            </>
        )
    }

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserDataId(rowData?._id);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/user/getAll", false));
        if (response) {
            setAllUsers(response?.Data);
        }
        setIsActive(false);
    };

    const allUsersStatic = [
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
    ]

    useEffect(() => {
        // getUserList()
    }, [])

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2" style={{ fontWeight: "700" }}>{addEditUser ? "Edit User" : "Add User"}</h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="card mt-4 flex flex-row justify-content-between p-5">
                <span style={{ fontWeight: "600", fontSize: "14px" }}> Inventory Status Report </span>
                <i className="pi pi-angle-right" style={{cursor:"pointer"}} onClick={() => history.push('./reportingDetails')}></i>
            </div>
            <div className="card mt-4 flex flex-row justify-content-between p-5">
                <span style={{ fontWeight: "600", fontSize: "14px" }}> Inventory Valuation Report </span>
                <i className="pi pi-angle-right" style={{cursor:"pointer"}} onClick={() => history.push('./reportingDetails')}></i>
            </div>
            <div className="card mt-4 flex flex-row justify-content-between p-5">
                <span style={{ fontWeight: "600", fontSize: "14px" }}> Stock Movement Report </span>
                <i className="pi pi-angle-right" style={{cursor:"pointer"}} onClick={() => history.push('./reportingDetails')}></i>
            </div>
            <div className="card mt-4 flex flex-row justify-content-between p-5">
                <span style={{ fontWeight: "600", fontSize: "14px" }}> Stock Aging Report </span>
                <i className="pi pi-angle-right" style={{cursor:"pointer"}} onClick={() => history.push('./reportingDetails')}></i>
            </div>
            <div className="card mt-4 flex flex-row justify-content-between p-5">
                <span style={{ fontWeight: "600", fontSize: "14px" }}> Asset Status Report </span>
                <i className="pi pi-angle-right" style={{cursor:"pointer"}} onClick={() => history.push('./reportingDetails')}></i>
            </div>

        </>
    );
};

export default Reporting