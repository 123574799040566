//Local Url:
// let baseURL = "http://192.168.12.141:7097"

//QA Url:
// let baseURL = "https://dev-wms.appinsnap.com"

//UAT Url:
let baseURL = "https://uat-whms.appinsnap.com"

export { baseURL };
