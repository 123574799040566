import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import loginImage from "../../assets/warehouse-assets/warehouse.png"
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { handleGetRequest } from "../../services/GetTemplate";

const Login = () => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [loadingIcon, setLoadingIcon] = useState('');
    const [isActive, setIsActive] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const validationSchema = Yup.object().shape({
        email: Yup.mixed().required("This field is required."),
        password: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
        },

        onSubmit: async (data) => {

            setLoadingIcon("pi pi-spin pi-spinner");
            setSaveBtnLoading(true);

            let obj = {
                user_Email: formik.values.email,
                user_Password: formik.values.password,
            }

            const response = await dispatch(handlePostRequest(obj, "/api/Authentication/Login", true, true));
            console.log("Login response:", response)

            if (response?.statusCode == 200) {

                localStorage.setItem("login", true)
                sessionStorage.setItem('firstLogin', 'true');
                getPermissionsByRoleId(response?.roleid)

                if (response?.user_id) {
                    localStorage.setItem("user_id", response?.user_id)
                }
                if (response?.warehouseid) {
                    localStorage.setItem("warehouseid", response?.warehouseid)
                }
                if (response?.roletypeid) {
                    localStorage.setItem("roletypeid", response?.roletypeid)
                }
                if (response?.usertype_id) {
                    localStorage.setItem("usertype_id", response?.usertype_id)
                }
                if (response?.user_name) {
                    localStorage.setItem("user_name", response?.user_name)
                }
                if (response?.img !== '') {
                    localStorage.setItem("img", response?.img)
                }
                if ((response?.roletypeid == 1 && response?.usertype_id == 0 && !response?.warehouseid) || (response?.roletypeid == 2 && response?.usertype_id == 2 && response?.warehouseid)) {
                    history.push("/");
                }
                else {
                    history.push("/");
                }
            }
            else {
                setSaveBtnLoading(false)
            }
            setLoadingIcon("");
            setSaveBtnLoading(false)
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getPermissionsByRoleId = async (roleId) => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Authentication/GetPermissionbyid?rid=${roleId}`, true));
        if (response) {
            localStorage.setItem("permissions", JSON.stringify(response));
        }
        setIsActive(false);
    }

    const getSuperAdminRoles = async (roleId) => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Authentication/Getallmodulebylist?roletype_id=${roleId}`, true));
        if (response) {
            localStorage.setItem("permissions", JSON.stringify(response));
        }
        setIsActive(false);
    }

    const RouteTo = () => {
        history.push("/forgotPassword")
    }

    useEffect(() => {
        if (formik.values.email) {
            localStorage.setItem("EmailForForgotPass", formik.values.email)
        }
    }, [formik.values.email])

    return (

        <>
            <LoadingOverlay
                active={isActive}
                spinner={<Triangle
                    height="120"
                    width="120"
                    color="#755fc8"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />}

                // text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                    })
                }}
            >

                <div className="login-page-container">

                    <div className="left-section">
                        <img src={loginImage} alt="Image Alt Text" className="imgMainLogo" />
                    </div>
                    <div className="right-section mb-6">
                        <div className="akwfs-logo">AKWFS</div>
                        <form onSubmit={formik.handleSubmit} className="login-form">
                            <div className="text-center justify-content-center">
                                <h1 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "700", color: "#6a4ed1" }}> WareHouse <span style={{ color: 'black' }} >  MS </span> </h1>
                                <h3 className="mt-7" style={{ color: "black", letterSpacing: "1px", fontWeight: "700" }}> Welcome back </h3>
                                <h6 className="mb-6" style={{ color: "black", letterSpacing: "1px", fontWeight: "400", color: "#8c8c8c" }}> Sign in by entering your account here </h6>
                            </div>

                            <div className="login-div mt-6">
                                <div class="input-card-login flex-column p-3">
                                    <div className='flex-row'>
                                        <label> Email </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <InputText className="p-inputtext-sm" id="email" name="email" placeholder='Enter your Email' value={formik.values.email} onChange={formik.handleChange} type="email" />
                                    <span className="ml-2">
                                        {getFormErrorMessage("email")}
                                    </span>
                                </div>

                                <div class="input-card-login flex-column p-3">
                                    <div className='flex-row'>
                                        <label> Password </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <Password
                                        placeholder="Enter your password"
                                        id="password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        toggleMask
                                        autoComplete="off"
                                        feedback={false}
                                        className="w-full custom-password-field"
                                    />
                                    <span className="ml-2">
                                        {getFormErrorMessage("password")}
                                    </span>
                                </div>

                                <h6 style={{ color: "#e18181", cursor: "pointer", textAlign: 'right', }} onClick={RouteTo}> Forgot Password ?</h6>
                                <div className='col-12 d-flex flex-row text-center mt-5'>
                                    <Button className="Login-Button" label="Login to Continue" iconPos="right" icon={loadingIcon || ""} loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                        <div className="powered-text">Powered by: AKWFS</div>
                    </div>
                </div>
            </LoadingOverlay>
        </>

    );
};

export default Login;
