import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { BrowserRouter, Routes, Route, useLocation, Switch, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./components/AppConfig/AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Login from "./pages/login/Login";
import ManageUsers from "./pages/UserManagement/ManageUsers";
import Settings from "./pages/Settings/Settings";
import AddEditUsers from "./pages/UserManagement/AddEditUsers";
import Dashboard from "./pages/Dashboard/Dashboard";
import ManageRoles from "./pages/RolesManagement/ManageRoles";
import AddEditRoles from "./pages/RolesManagement/AddEditRoles";
import AssignPermissions from "./pages/RolesManagement/AssignPermissions";
import ManageCategory from "./pages/CategoryManagement/ManageCategory";
import AddEditCategory from "./pages/CategoryManagement/AddEditCategory";
import ManageInventory from "./pages/InventoryManagement/ManageInventory";
import AddEditInventory from "./pages/InventoryManagement/AddEditInventory";
import CheckIn from "./pages/InventoryManagement/CheckIn/CheckIn";
import CheckOut from "./pages/InventoryManagement/CheckOut/CheckOut";
import ManageAsset from "./pages/AssetManagement/ManageAsset";
import AddEditAsset from "./pages/AssetManagement/AddEditAsset";
import CheckInAsset from "./pages/AssetManagement/CheckInAsset";
import BorrowAsset from "./pages/AssetManagement/BorrowAsset";
import ManageRFID from "./pages/RfidManagement/ManageRFID";
import { confirmDialog } from "primereact/confirmdialog";
import Reporting from "./pages/Reporting/Reporting";
import ReportingDetails from "./pages/Reporting/ReportingDetails";
import ManageWarehouse from "./pages/WarehouseManagement/ManageWarehouse";
import AddEditWarehouse from "./pages/WarehouseManagement/AddEditWarehouse";
import ForgotPassword from "./pages/login/forgetpassword/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword/ResetPassword";
import dashboardIcon from "../src/assets/warehouse-assets/dashboard-icons/dashboard.png"
import AssignPermissions2 from "./pages/RolesManagement/AssignPermissions2";
import AddEditUsersSuperAdmin from "./pages/UserManagement/AddEditUsersSuperAdmin";
import ManageVendor from "./pages/vendorManagement/ManageVendor";
import ManageBrand from "./pages/BrandManagement/ManageBrand";
import AddEditVendor from "./pages/vendorManagement/AddEditVendor";
import AddEditSubCategory from "./pages/CategoryManagement/AddEditSubCategory";
import AddEditBrand from "./pages/BrandManagement/AddEditBrand";
import AddEditUsersWH from "./pages/UserManagement/AddEditUsersWH";
import { Dialog } from 'primereact/dialog';
import CheckInGrid from "./pages/InventoryManagement/CheckIn/CheckInGrid";
import CheckOutGrid from "./pages/InventoryManagement/CheckOut/CheckOutGrid";

const App = () => {

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [dynamicMenu, setDynamicMenu] = useState([]);
    const [countdown, setCountdown] = useState(5);
    const [showPopup, setShowPopup] = useState(false);

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")
    const login = localStorage.getItem('login')

    const intervalRef = useRef();
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()

    useEffect(() => {
        if (roletypeid) {

            const timeoutId = setTimeout(() => {
                getPermissions(); // Call getPermissions after 2 second
            }, 3000);

            return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount or `roletypeid` change
        }
    }, [roletypeid]);

    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    const getPermissions = () => {
        // setShowLoader(true)
        const permissions = localStorage.getItem('permissions');
        if (!permissions) {
            console.log("No permissions found in local storage.");
        }

        const parsedPermissions = JSON.parse(permissions);

        if (parsedPermissions?.length) {
            console.log('if')
            let menuItems = parsedPermissions && parsedPermissions.map(module => {
                const subMenuItems = module.submodules && module.submodules.length > 0 ? module.submodules.map(subModule => ({
                    label: subModule?.contentName,
                    icon: "",
                    to: subModule?.routepath,
                })) : null;

                return {
                    label: module?.contentName,
                    icon: module?.icon_class,
                    to: module?.routepath,
                    items: subMenuItems
                };
            });

            menuItems.push({
                label: "Logout",
                icon: "custom-logout-icon",
                command: handleLogOut
            });

            setDynamicMenu([{ items: menuItems }]);
            // Navigate to the first module's route if available
            if (sessionStorage.getItem('firstLogin') === 'true') {
                sessionStorage.removeItem('firstLogin');  // Remove from localStorage
                if (menuItems?.length > 0 && menuItems[0].to) {
                    history.push(menuItems[0]?.to);
                }
            }
            return
        }
        else {
            console.log('else')
            let menuItems = []
            menuItems.push({
                label: "Logout",
                icon: "custom-logout-icon",
                command: handleLogOut
            });

            setDynamicMenu([{ items: menuItems }]);
            return
        }
        // setShowLoader(false)
    };

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {

        menuClick = true;
        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event?.item?.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const handleLogOut = () => {
        setDynamicMenu([])
        localStorage.clear();
        window.localStorage.clear();
        window.sessionStorage.clear();
        history.push("/");
    };

    const sideBarMenu = [
        {
            items: [
                {
                    label: "Dashboard",
                    icon: "custom-dashboard-icon",
                    to: "/Dashboard",
                },
                {
                    label: "User Management",
                    icon: "custom-user-icon",
                    to: "/users",
                },
                {
                    label: "Role Management",
                    icon: "custom-role-icon",
                    to: "/roles",
                },

                {
                    label: "RFID Labelling",
                    icon: "custom-rfid-icon",
                    to: "/Rfid",
                },

                ...((roletypeid == 1 && usertype_id == 0 && !warehouseid)
                    ? [{ label: "Warehouse Management", icon: "custom-warehouse-icon", to: "/warehouse" }]
                    : []),

                {
                    label: "Inventory Management",
                    icon: "custom-inventory-icon",
                    to: "/inventory",
                    items: [
                        { label: "Check In", icon: "", to: "/checkin" },
                        { label: "Check Out", icon: "", to: "/checkout" },
                    ],
                },
                {
                    label: "Asset Management",
                    icon: "custom-asset-icon",
                    to: "/asset",
                    items: [
                        { label: "Check In", icon: "", to: "/checkinasset" },
                        { label: "Borrow", icon: "", to: "/borrowasset" },
                    ],
                },
                {
                    label: "Reporting",
                    icon: "custom-reporting-icon",
                    to: "/reporting",
                },
                {
                    label: "Setups",
                    icon: "custom-setups-icon",
                    to: "/",
                    items: [
                        { label: "Category Setup", icon: "", to: "/category" },
                        { label: "Sub Category Setup", icon: "", to: "/assignsubcategory" },
                        { label: "Brand Setup", icon: "", to: "/brand" },
                        { label: "Vendor Setup", icon: "", to: "/vendor" },
                    ],
                },
                {
                    label: "Logout",
                    icon: "custom-logout-icon",
                    command: handleLogOut
                },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    function checkActivity() {
        if (localStorage.getItem("login") === "false" || localStorage.getItem("login") === null) {
            return;
        }

        const lastActive = localStorage.getItem('lastActive');
        const elapsed = Date.now() - lastActive;
        if (elapsed > 1800000) { // 30 minutes in milliseconds
            console.log("one minute complete inside");
            setShowPopup(true);

            let countdownValue = 5;
            const countdownInterval = setInterval(() => {
                setCountdown(countdownValue);
                countdownValue -= 1;
                if (countdownValue < 0) {
                    clearInterval(countdownInterval);
                    setShowPopup(false);
                    logoutUser();
                }
            }, 1000);
        }
    }

    function logoutUser() {
        localStorage.removeItem('lastActive');
        localStorage.removeItem("permissions");
        localStorage.removeItem("login");
        window.localStorage.clear();
        window.sessionStorage.clear();
        localStorage.clear();
        history.push("/");
        clearInterval(intervalRef.current);
    }

    // Set up an interval to run the checkActivity function every 30 minutes
    useEffect(() => {
        intervalRef.current = setInterval(checkActivity, 1800000);
        return () => clearInterval(intervalRef.current);
    }, []);

    // Update the user's last active time whenever they interact with the page
    useEffect(() => {
        if (login) {
            function handleActivity() {
                localStorage.setItem('lastActive', Date.now());
            }

            window.addEventListener('click', handleActivity);
            window.addEventListener('keydown', handleActivity);
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('mousedown', handleActivity);
            window.addEventListener('scroll', handleActivity);
            window.addEventListener('keypress', handleActivity);

            return () => {
                window.removeEventListener('click', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('mousedown', handleActivity);
                window.removeEventListener('scroll', handleActivity);
                window.removeEventListener('keypress', handleActivity);
            };
        }
    }, [login]);

    useEffect(() => {
        if (!login || login === null) {
            localStorage.removeItem('lastActive');
        }
    }, [login]);

    LoadingOverlay.propTypes = undefined;

    return (

        <div className={wrapperClass} onClick={onWrapperClick}>

            {showPopup && (
                <Dialog header="Logging Out" visible={showPopup} modal onHide={() => { }}>
                    <p>Due to inactivity, the system is logging out in {countdown} seconds...</p>
                </Dialog>
            )}

            {/* <LoadingOverlay active={isLoading} spinner text="Loading ..."> */}
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            {(localStorage.getItem("login") === null || localStorage.getItem("login") === undefined || !localStorage.getItem("login")) ?
                (
                    <>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/forgotPassword" component={ForgotPassword} />
                        <Route exact path="/resetPassword" component={ResetPassword} />
                    </>
                ) : (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onMenuItemClick}>
                            {dynamicMenu?.length === 0 ? (
                                <div className="loader-container">
                                    <div className="loading-text">Loading your content...</div>
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <AppMenu model={(roletypeid == 2 && usertype_id == 2 && warehouseid) ? dynamicMenu : dynamicMenu}
                                    onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                            )}
                        </div>

                        <div className="layout-main-container innr-Body">
                            <div className="layout-main">
                                <Switch>
                                    {/* <Route path="/login" component={Login} /> */}
                                    <Route exact path="/Dashboard" component={Dashboard} />
                                    <Route exact path="/settings" component={Settings} />
                                    <Route exact path="/users" component={ManageUsers} />
                                    <Route exact path="/addeditusers" component={AddEditUsers} />
                                    <Route exact path="/addedituser" component={AddEditUsersSuperAdmin} />
                                    <Route exact path="/addedituserr" component={AddEditUsersWH} />
                                    
                                    <Route exact path="/roles" component={ManageRoles} />
                                    <Route exact path="/addeditroles" component={AddEditRoles} />
                                    <Route exact path="/assignpermissions" component={AssignPermissions} />
                                    <Route exact path="/assignpermissions2" component={AssignPermissions2} />
                                    <Route exact path="/category" component={ManageCategory} />
                                    <Route exact path="/addeditcategory" component={AddEditCategory} />
                                    <Route exact path="/addeditsubcategory" component={AddEditSubCategory} />
                                    <Route exact path="/inventory" component={ManageInventory} />
                                    <Route exact path="/addeditinventory" component={AddEditInventory} />
                                    <Route exact path="/checkinaddedit" component={CheckIn} />
                                    <Route exact path="/checkin" component={CheckInGrid} />
                                    <Route exact path="/checkout" component={CheckOutGrid} />
                                    <Route exact path="/checkoutaddedit" component={CheckOut} />
                                    <Route exact path="/asset" component={ManageAsset} />
                                    <Route exact path="/addeditasset" component={AddEditAsset} />
                                    <Route exact path="/checkinasset" component={CheckInAsset} />
                                    <Route exact path="/borrowasset" component={BorrowAsset} />
                                    <Route exact path="/Rfid" component={ManageRFID} />
                                    <Route exact path="/reporting" component={Reporting} />
                                    <Route exact path="/reportingDetails" component={ReportingDetails} />
                                    <Route exact path="/warehouse" component={ManageWarehouse} />
                                    <Route exact path="/addeditwarehouse" component={AddEditWarehouse} />
                                    <Route exact path="/vendor" component={ManageVendor} />
                                    <Route exact path="/brand" component={ManageBrand} />
                                    <Route exact path="/addeditbrand" component={AddEditBrand} />
                                    <Route exact path="/addeditvendor" component={AddEditVendor} />
                                </Switch>
                            </div>
                            {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                        </div>
                    </>
                )
            }

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
            {/* </LoadingOverlay> */}
        </div>
    );
};


export default App;
