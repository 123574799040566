import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../Config";
import { SplitButton } from "primereact/splitbutton";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { handlePutRequest } from "../../services/PutTemplate";
import dp from '../../assets/warehouse-assets/dp.jpeg'
import { Tag } from "primereact/tag";
import { Image } from "primereact/image";

const ManageUsers = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")


    const [superAdminActive, setSuperAdminActive] = useState(false)
    const [superAdminActiveAdd, setSuperAdminActiveAdd] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [allUsers, setAllUsers] = useState([]);
    const [allUsers2, setAllUsers2] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');
    const [userTypes, setUserTypes] = useState([]);
    const [reason, setReason] = useState();
    const [dropdownUserType, setDropdownUserType] = useState('');
    const tempReasonRef = useRef('');

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    useEffect(() => {
        if (roletypeid) {
            if (roletypeid == 1 && usertype_id == 0 && !warehouseid || roletypeid == 2 && usertype_id == 1 && !warehouseid) {
                setSuperAdminActive(true)
                setSuperAdminActiveAdd(true)
            }
            else {
                setSuperAdminActive(false)
                setSuperAdminActiveAdd(false)
            }
        }
    }, [roletypeid])

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [
            {
                label: 'Edit',
                command: () => handleEditUsers(rowData),
            },
            {
                label: 'Deactivate',
                command: () => confirm(rowData),
            },
        ];

        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: (
                <div>
                    <br />
                    <InputTextarea
                        maxLength={100}
                        placeholder='Enter your deactivate Reason'
                        defaultValue={tempReasonRef.current}
                        onChange={(e) => tempReasonRef.current = e.target.value} // Update the ref
                        className="p-inputtext-sm delete-popup mt-2 mb-2 p-3"
                        rows={3}
                    ></InputTextarea>
                </div>
            ),
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => {
                setReason(tempReasonRef.current);
                handleDeleteUser(rowData, tempReasonRef.current);
            },
            reject: () => {
                setReason('');
                tempReasonRef.current = '';
            },
        });
    };

    const handleDeleteUser = async (rowData, reason) => {

        const obj = {
            user_id: rowData?.id,
            deactivate_Reason: reason,
        };

        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, `/api/Authentication/DeactivateUser`, true, true));
        if (response?.statusCode == 200) {
            await getUserList();
            setReason('');
            tempReasonRef.current = '';
        }

        setIsActive(false);
    }

    const handleEditUsers = (rowData) => {
        const item = true;
        if (roletypeid == 1 || roletypeid == 2 && usertype_id == 1 && !warehouseid) {
            history.push({
                pathname: './addedituser',
                state: {
                    isEditable: item,
                    rowData: rowData,
                    superAdminActive: superAdminActive,
                }
            });
        }
        else {

            history.push({
                pathname: './addedituserr',
                state: {
                    isEditable: item,
                    rowData: rowData,
                    superAdminActive: superAdminActive,
                }
            });
        }
    };

    const imageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData?.image ?
                        <Image src={`${baseURL}${rowData?.image}`} preview zoomSrc={`${baseURL}${rowData?.image}`} className='mr-3' alt='dp' width="45" height="45" borderRadius='50%' style={{ width: '45px', height: '45px', borderRadius: "50%", }} />
                        :
                        <Image src={dp} zoomSrc={dp} preview className='mr-3' alt='dp' width="45" height="45" borderRadius='50%' style={{ width: '45px', height: '45px', borderRadius: "50%", }} />
                    }
                </div>
            </React.Fragment>
        );
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Authentication/GetAllUsers?roletype_id=${roletypeid}&usertype_id=${usertype_id}&warehouse_id=${warehouseid || 0}`, true));
        console.log('response', response)
        if (response) {
            setAllUsers(response);
            setAllUsers2(response);
        }
        setIsActive(false);
    };

    const getUserTypes = async () => {

        setIsActive(true);
        const res = await dispatch(handleGetRequest("/api/Authentication/UserType", true));
        console.log('res', res)
        if (res) {
            setUserTypes(res);
        }
        setIsActive(false);
    };


    useEffect(() => {
        getUserList()
        getUserTypes()
    }, [])

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/users"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.permission_id) || []);

        }
    }, []);

    useEffect(() => {
        if (dropdownUserType) {
            if (dropdownUserType == 1 || dropdownUserType == 2) {
                setAllUsers(allUsers2?.filter(item => item?.usertypeId == dropdownUserType))
            }
            else {
                setAllUsers(allUsers2)
            }
        }

    }, [dropdownUserType])

    return (

        <>
            <div className="flex flex-row custom-alignment justify-content-end">
                <span className="p-input-icon-left mr-2 resp-margin">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        className="search-input2"
                    />
                </span>
                <div className="resp-margin">
                    <Dropdown
                        options={
                            [
                                { name: 'System user', id: 1 },
                                { name: 'WareHouse Manager', id: 2 },
                                { name: 'All', id: 3 },
                            ]
                        }
                        optionLabel="name"
                        optionValue="id"
                        value={dropdownUserType}
                        onChange={(e) => setDropdownUserType(e.value)}
                        className="dropdown-filter ml-2 mr-1"
                        icon="pi pi-plus"
                        placeholder="--Select User type--"
                    />
                </div>
                {permissions.includes(2) ?
                    <div className="">
                        <Button
                            label="Add New"
                            className="Add__New-Button ml-2"
                            icon="pi pi-plus"
                            onClick={() => {
                                const item = false;
                                history.push({
                                    pathname: './addeditusers',
                                    state: {
                                        isEditable: item,
                                        superAdminActiveAdd: superAdminActiveAdd
                                    }
                                });
                            }}
                        />
                    </div>
                    :
                    null
                }
            </div>

            <div className="card mt-3">
                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "user_id",
                            "name",
                            "usertypename",
                            "warehouseName",
                            "rfiD_code",
                            "role",
                            "email",
                            "status",
                            "createdDateandFiringdate",
                            "lastLogin",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsers}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column field="user_id" sortable body={(rowData) => rowData?.user_id || 'N/A'} header="User Id"></Column>
                        <Column body={imageTemplate} header="Profile Pic"></Column>
                        <Column
                            field="name"
                            sortable
                            body={(rowData) => (
                                <div>
                                    {<div style={{ whiteSpace: 'pre-wrap' }}>
                                        {rowData?.name.replace(/(.{25})/g, '$1\n')}
                                    </div> || 'N/A'}
                                </div>
                            )}
                            header="Name"
                        ></Column>
                        <Column field="usertypename" sortable body={(rowData) => rowData?.usertypename || 'N/A'} header="User Type"></Column>
                        <Column field="warehouseName" sortable body={(rowData) => rowData?.warehouseName || 'N/A'} header="WareHouse Name"></Column>
                        <Column
                            field="email"
                            sortable
                            body={(rowData) =>
                                rowData?.email ? (
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {rowData.email.replace(/(.{25})/g, '$1\n')}
                                    </div>
                                ) : (
                                    'N/A'
                                )
                            }
                            header="Email"
                        ></Column>
                        <Column field="role" sortable body={(rowData) => rowData?.role || 'N/A'} header="Role"></Column>
                        <Column
                            field="rfiD_code"
                            sortable
                            body={(rowData) => (rowData?.rfiD_code == 0 || !rowData?.rfiD_code) ? 'N/A' : rowData.rfiD_code}
                            header="RFID Code"
                        />
                        <Column
                            sortable
                            field="status"
                            body={(rowData) => (
                                <Tag severity={rowData?.status == 'Active' ? "success" : "danger"} value={rowData?.status || 'N/A'}></Tag>
                            )}
                            header="Status"
                        >
                        </Column>
                        <Column field="createdDateandFiringdate" sortable body={(rowData) => rowData?.createdDateandFiringdate || 'N/A'} header="Enrollment-Firing"></Column>
                        <Column field="lastLogin" sortable body={(rowData) => rowData?.lastLogin || 'N/A'} header="Last Log Activity"></Column>
                        {permissions.includes(1) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null}
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageUsers