import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/warehouse-assets/colored/edit.png"
import deleteIcon from "../../assets/warehouse-assets/colored/dlet.png"
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { handlePutRequest } from "../../services/PutTemplate";
import { Tag } from "primereact/tag";

const ManageRoles = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [isActive, setIsActive] = useState(false)
    const [allRoles, setAllRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState('p-button-danger');


    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [
            {
                label: 'Edit',
                command: () => handleEditRoles(rowData),
            },
            {
                label: 'Deactivate',
                command: () => confirm(rowData),
            },
        ];

        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to deactivate this role ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: acceptButtonClass,
            acceptLabel: 'Yes',
            accept: () => handleDeleteRole(rowData),
            reject: () => {
                return null
            },
        });
    };

    const handleDeleteRole = async (rowData) => {

        const obj = {
            role_id: rowData?.id,
        };

        setIsActive(true);

        const response = await dispatch(handlePutRequest(obj, `/api/Authentication/DeactivateRole`, true, true));
        console.log('response', response)
        if (response?.statusCode == 200) {
            await getRolesList();
        }
        setIsActive(false);
    }

    const handleEditRoles = (rowData) => {
        const item = true;
        history.push({
            pathname: './addeditroles',
            state: {
                isEditable: item,
                rowData: rowData,
            }
        });
    };

    //Get All Roles
    const getRolesList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/Authentication/getAllRole?rid=${roletypeid || 0}&uid=${usertype_id || 0}&wid=${warehouseid || 0}`, false));
        console.log("response", response)
        if (response) {
            setAllRoles(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getRolesList()
    }, [])

    const rowIndexTemplate = (rowData, props) => {
        let index = parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };

    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/Roles"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.permission_id) || []);
        }
    }, []);

    return (
        <>

            <div className="flex flex-row custom-alignment justify-content-end">
                <span className="p-input-icon-left mr-3 resp-margin">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        className="search-input"
                    />
                </span>
                <div className="resp-margin">
                    <Button
                        label="Assign Permissions"
                        className="Add__New-Button mt-1"
                        onClick={() => {
                            history.push('./assignpermissions')
                        }}
                    />
                </div>
                {permissions.includes(2) ?
                    <div className="">
                        <Button
                            label="Add New"
                            className="Add__New-Button ml-3 mt-1"
                            icon="pi pi-plus"
                            onClick={() => {
                                const item = false;
                                history.push({
                                    pathname: './addeditroles',
                                    state: {
                                        isEditable: item,
                                    }
                                });
                            }}
                        />
                    </div>
                    :
                    null
                }

            </div>
            <div className="card mt-3">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#755fc8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "name",
                            "description",
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allRoles}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={rowIndexTemplate} header="S.No" />
                        <Column sortable field="name" header="Role Name"></Column>
                        <Column sortable field='roleDescription' body={(rowData) => rowData?.roleDescription || 'N/A'} header="Description"></Column>
                        <Column sortable field='createddatetime' body={(rowData) => rowData?.createddatetime || 'N/A'} header="Activation Date"></Column>
                        <Column
                            sortable
                            field="status"
                            body={(rowData) => (
                                <Tag severity={rowData?.statusdesc == 'Active' ? "success" : "danger"} value={rowData?.statusdesc || 'N/A'}></Tag>
                            )}
                            header="Status"
                        />
                        {permissions.includes(1) ?
                            <Column header="Action" body={actionTemplate} />
                            :
                            null}
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageRoles