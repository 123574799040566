import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddEditUsers from "./AddEditRoles"
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/warehouse-assets/colored/edit.png"
import deleteIcon from "../../assets/warehouse-assets/colored/dlet.png"
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import './AssignPermissions.scss'

const AssignPermissions2 = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [userdataId, setUserDataId] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [selectedModules, setSelectedModules] = useState([]);
    const [selectedSubmodules, setSelectedSubmodules] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState({});

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        return (
            <div className="">
                <Button className='edit-btn ml-3' onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className='edit-btn mr-3' onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Edit" />
                </Button>
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this User ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteUser(rowData),
            reject: null
        });
    }

    const handleDeleteUser = async (rowData) => {

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            await getUserList()
        }

        else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/")
            toast.info("Please Login again")
        }

        setIsActive(false);
    }

    const nameTemplate = (rowData) => {

        const result = `${rowData.first_name} ${rowData?.last_name}`
        return (
            <>
                {result}
            </>
        )
    }

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserDataId(rowData?._id);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/user/getAll", false));
        if (response) {
            setAllUsers(response?.Data);
        }
        setIsActive(false);
    };

    const allUsersStatic = [
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
        {
            email: 'abc@gmail.com', cnic: '222344334', contact: '03363635332', first_name: "Hammad", last_name: 'Ahsan'
        },
    ]

    useEffect(() => {
        // getUserList()
    }, [])

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2" style={{ fontWeight: "700" }}>{addEditUser ? "Edit User" : "Add User"}</h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        )
    }



    const rolePermissions = [
        {
            "_id": "63f8663f4177b3b558834ba6",
            "label": "Dashboard",
            "route": "/dashboard",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },
        {
            "_id": "63f866124177b3b558834ba2",
            "label": "User Management",
            "route": "/users",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },
        {
            "_id": "63f865ed4177b3b558834ba0",
            "label": "Role Management",
            "route": "/roles",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },
        {
            "_id": "63f866e04177b3b558834bb4",
            "label": "Inventory Management",
            "route": "/inventory",
            "isSubModule": true,
            "permissions": [
                { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" }
            ],
            "sub_modules": [
                {
                    "_id": "63f86e58b7ab76e4782b7d42",
                    "label": "Check In",
                    "route": "/checkin",
                    "permissions": [
                        { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                        { "_id": "63f79d2002de67a77326784c", "name": "view" },
                        { "_id": "63f79d6102de67a77326784d", "name": "create" }
                    ]
                },
                {
                    "_id": "63f86e67b7ab76e4782b7d44",
                    "label": "Check Out",
                    "route": "/checkout ",
                    "permissions": [
                        { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                        { "_id": "63f79d2002de67a77326784c", "name": "view" },
                        { "_id": "63f79d6102de67a77326784d", "name": "create" }
                    ]
                }
            ]
        },
        {
            "_id": "63f866694177b3b558834baa",
            "label": "RFID Labelling",
            "route": "/Rfid",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },
        {
            "_id": "63f865aa4177b3b558834b9e",
            "label": "Asset Management",
            "route": "/asset",
            "isSubModule": true,
            "permissions": [
                { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" }
            ],
            "sub_modules": [
                {
                    "_id": "63f86e58b7ab76e4782b8d42",
                    "label": "Check In",
                    "route": "/checkin",
                    "permissions": [
                        { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                        { "_id": "63f79d2002de67a77326784c", "name": "view" },
                        { "_id": "63f79d6102de67a77326784d", "name": "create" }
                    ]
                },
                {
                    "_id": "63f86e67b7ab76e7782b7d44",
                    "label": "Borrowed Asset",
                    "route": "/checkout ",
                    "permissions": [
                        { "_id": "63f79d8302de67a77326784e", "name": "manage" },
                        { "_id": "63f79d2002de67a77326784c", "name": "view" },
                        { "_id": "63f79d6102de67a77326784d", "name": "create" }
                    ]
                }
            ]
        },
        {
            "_id": "63f8667b4177b3b558834bac",
            "label": "Caetgory Management",
            "route": "/category",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },
        {
            "_id": "63f866124177b3b551824ba2",
            "label": "Reporting",
            "route": "/reporting",
            "isSubModule": false,
            "permissions": [
                { "_id": "63f79d2002de67a77326784c", "name": "view" },
                { "_id": "63f79d6102de67a77326784d", "name": "create" },
                { "_id": "63f79d8302de67a77326784e", "name": "manage" }
            ],
            "sub_modules": []
        },

    ]

    const handleModuleChange = (moduleId, isSubModule) => {
        setSelectedModules((prevModules) =>
            prevModules.includes(moduleId)
                ? prevModules.filter((id) => id !== moduleId)
                : [...prevModules, moduleId]
        );

        // Check if the main module ID is not in selectedModules
        const isMainModuleSelected = selectedModules.includes(moduleId);

        // Remove submodule IDs associated with the main module if it's not selected
        if (!isMainModuleSelected) {
            const module = rolePermissions.find((mod) => mod._id.includes(moduleId));
            if (module) {
                module.sub_modules.forEach((subModule) => {
                    const subModuleId = subModule._id;
                    if (selectedSubmodules.includes(subModuleId)) {
                        setSelectedSubmodules((prevSubmodules) =>
                            prevSubmodules.filter((id) => id !== subModuleId)
                        );
                        // Remove permissions for the submodule
                        setSelectedPermissions((prevPermissions) => {
                            const updatedPermissions = { ...prevPermissions };
                            delete updatedPermissions[subModuleId];
                            return updatedPermissions;
                        });
                    }
                });
            }
        }
        else {
            const mainModuleSelected = selectedModules.includes(moduleId);
            if (mainModuleSelected) {
                const module = rolePermissions.find((mod) => mod._id.includes(moduleId));
                const removeIds = module?.sub_modules?.map((item) => item?._id);
                if (removeIds) {
                    // Remove all associated submodule ids from selectedSubmodules array
                    const removeAssociatedIds = selectedSubmodules.filter((id) => !removeIds.includes(id))

                    setSelectedSubmodules(removeAssociatedIds)
                    return
                    // Remove permissions for the removed submodules
                    setSelectedPermissions((prevPermissions) => {
                        const updatedPermissions = { ...prevPermissions };
                        removeIds.forEach((subModuleId) => {
                            delete updatedPermissions[subModuleId];
                        });
                        return updatedPermissions;
                    });
                }
            }
        }

        // Rest of the function remains the same
        const module = rolePermissions.find((mod) => mod._id === moduleId);
        if (module) {
            const newSelectedSubmodules = [...selectedSubmodules];
            const newSelectedPermissions = { ...selectedPermissions };

            // Handle Submodules and their Permissions
            module.sub_modules.forEach((subModule) => {
                const subModuleId = subModule._id;
                if (!newSelectedSubmodules.includes(subModuleId)) {
                    newSelectedSubmodules.push(subModuleId);
                }

                if (!newSelectedPermissions[subModuleId]) {
                    newSelectedPermissions[subModuleId] = {};
                }

                if (subModule.permissions && subModule.permissions.length > 0) {
                    if (!newSelectedPermissions[subModuleId]) {
                        newSelectedPermissions[subModuleId] = {};
                    }

                    // Check if there are permissions for the submodules
                    subModule.permissions.forEach((permission) => {
                        // Assign true to the specific key inside the object
                        newSelectedPermissions[subModuleId][permission._id] = true;
                    });
                }
            });

            // Handle Permissions for the selected main module
            if (!isSubModule && module.permissions && module.permissions.length > 0) {
                if (!newSelectedPermissions[moduleId]) {
                    newSelectedPermissions[moduleId] = {};
                }

                module.permissions.forEach((permission) => {
                    newSelectedPermissions[moduleId][permission._id] = true;
                });
            }

            setSelectedSubmodules(newSelectedSubmodules);
            setSelectedPermissions(newSelectedPermissions);
        }
    };

    // Function to handle submodule selection
    const handleSubmoduleChange = (submoduleId) => {
        setSelectedSubmodules((prev) =>
            prev.includes(submoduleId) ? prev.filter((id) => id !== submoduleId) : [...prev, submoduleId]
        );
    };

    // Function to handle permission selection
    const handlePermissionChange = (moduleId, permissionId) => {
        setSelectedPermissions((prev) => ({
            ...prev,
            [moduleId]: {
                ...prev[moduleId],
                [permissionId]: !prev[moduleId]?.[permissionId],
            },
        }));
    }

    const handleSelectAll = () => {
        if (rolePermissions) {
            const newSelectedModules = [];
            const newSelectedSubmodules = [];
            const newSelectedPermissions = {};

            rolePermissions.forEach((module) => {
                // Handle Main Modules
                newSelectedModules.push(module._id);

                // Handle Submodules
                if (module.isSubModule) {
                    newSelectedSubmodules.push(module._id);
                }

                // Handle Permissions for Main Module
                if (!module.isSubModule && module.permissions && module.permissions.length > 0) {
                    newSelectedPermissions[module?._id] = {};
                    module.permissions.forEach((permission) => {
                        newSelectedPermissions[module?._id][permission?._id] = true;
                    });
                }

                // Handle Submodules and their Permissions
                module.sub_modules.forEach((subModule) => {
                    newSelectedSubmodules.push(subModule?._id);

                    if (!newSelectedPermissions[subModule?._id]) {
                        newSelectedPermissions[subModule?._id] = {};
                    }

                    // Handle Permissions for Submoduless
                    if (subModule.permissions && subModule.permissions.length > 0) {
                        const subModuleId = subModule?._id;

                        if (!newSelectedPermissions[subModuleId]) {
                            newSelectedPermissions[subModuleId] = {};
                        }

                        // Check if there are permissions for the submodulessss
                        subModule.permissions.forEach((permission) => {
                            // Assign true to the specific key inside the objecttttt
                            newSelectedPermissions[subModuleId][permission?._id] = true;
                        });
                    }
                });
            });

            // Set the selected modules, submodules, and permissions
            setSelectedModules(newSelectedModules);
            setSelectedSubmodules(newSelectedSubmodules);
            setSelectedPermissions(newSelectedPermissions);
        }
    };

    const handleDeSelectAll = () => {
        setSelectedModules([]);
        setSelectedSubmodules([]);
        setSelectedPermissions([]);
    }

    return (

        <>
            <div className="card-assign-permissions mt-3">
                <div style={{ overflowX: 'auto' }}>
                    <div class="col-12 assign-header">
                        <div class="col-12 lg:col-3 md:col-12">
                            <div class="input-card flex flex-column p-2">
                                <div className='flex flex-row'>
                                    <label> User Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown
                                    placeholder='--Select UserType--'
                                    id="role"
                                    name="role"
                                    options=''
                                    optionLabel='role_Name'
                                    optionValue='_id'
                                    value=''
                                    onChange=''
                                    className="p-inputtext-sm"
                                    autoComplete="off"
                                ></Dropdown>

                            </div>
                        </div>
                        <div class="col-12 lg:col-3 md:col-12">
                            <div class="input-card flex flex-column p-2">
                                <div className='flex flex-row'>
                                    <label> Role </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown
                                    placeholder='--Select Role--'
                                    id="role"
                                    name="role"
                                    options=''
                                    optionLabel='role_Name'
                                    optionValue='_id'
                                    value=''
                                    onChange=''
                                    className="p-inputtext-sm"
                                    autoComplete="off"
                                ></Dropdown>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 mt-6 md:col-6">
                            <Button style={{ float: "right" }} type="button" label="Select All" aria-label="Select All" className="selectButton float-right" onClick={handleSelectAll} />
                            <Button style={{ float: "right" }} type="button" label="Deselect All" aria-label="Deselect All" className="deselectButton mr-2 float-right" onClick={handleDeSelectAll} />
                        </div>
                    </div>
                    <table className='col-12 card datatable-responsive' >
                        <thead className='card' style={{ height: "40px", color: "black", fontWeight: 'lighter', padding: "20px 2px" }}>
                            <tr>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Module</th>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Sub Module</th>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Permissions</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#efefef", color: "black" }}>
                            {rolePermissions?.map((module) => (
                                <tr key={module._id}>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        <label className="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={selectedModules.includes(module._id)}
                                                onChange={() => handleModuleChange(module._id, module.isSubModule)}
                                            />
                                            <span className="checkmark">
                                                <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                            </span>
                                            {module.label}
                                        </label>
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        {module.isSubModule && selectedModules.includes(module._id) && (
                                            module.sub_modules.map((submodule) => (
                                                <div key={submodule._id}>
                                                    <label className="custom-checkbox mb-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedSubmodules.includes(submodule._id)}
                                                            onChange={() => handleSubmoduleChange(submodule._id)}
                                                        />
                                                        <span className="checkmark">
                                                            <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                        </span>
                                                        {submodule.label}
                                                    </label>
                                                </div>
                                            ))
                                        )}
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        {!module.isSubModule && selectedModules.includes(module._id) ? (
                                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                {module.permissions.map((permission) => (
                                                    <label key={permission._id} style={{ marginRight: "10px" }} className="custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedPermissions[module._id]?.[permission._id]}
                                                            onChange={() => handlePermissionChange(module._id, permission._id)}
                                                        />
                                                        <span className="checkmark">
                                                            <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                        </span>
                                                        {permission.name}
                                                    </label>
                                                ))}
                                            </div>
                                        ) : (
                                            // Display submodules and their permissions for selected main module
                                            module.sub_modules
                                                .filter((submodule) => selectedSubmodules.includes(submodule._id))
                                                .map((submodule, index, array) => (
                                                    <React.Fragment key={submodule._id}>
                                                        <div className="moduleContainer" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                            {submodule.permissions.map((permission) => (
                                                                <React.Fragment key={permission._id}>
                                                                    <label style={{ marginRight: "10px" }} className="custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedPermissions[submodule._id]?.[permission._id]}
                                                                            onChange={() => handlePermissionChange(submodule._id, permission._id)}
                                                                        />
                                                                        <span className="checkmark">
                                                                            <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                                        </span>
                                                                        {permission.name}
                                                                    </label>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            {index < array.length - 1 && <hr style={{ borderTop: "1px solid #b6b6b6", width: "80%", margin: "0.5rem 2.7rem" }} />}
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AssignPermissions2