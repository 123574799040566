import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import './AssignPermissions.scss'
import { handlePostRequest } from "../../services/PostTemplate";

const AssignPermissions = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const [role, setRole] = useState('')
    const [allRoles, setAllRoles] = useState([])
    const [loadingIcon, setLoadingIcon] = useState('');
    const [myApiData, setMyApiData] = useState([])
    const [isActive, setIsActive] = useState(false)
    const [selectedModules, setSelectedModules] = useState([]);
    const [selectedSubmodules, setSelectedSubmodules] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState({});

    console.log('selectedModules', selectedModules)
    console.log('selectedSubmodules', selectedSubmodules)
    console.log('selectedPermissions', selectedPermissions)

    const handleSubmit = async () => {
        let payload = [];
        setLoadingIcon("pi pi-spin pi-spinner");
        // Handle main modules
        selectedModules.forEach((moduleId) => {
            const module = myApiData.find(mod => mod.id === moduleId);
            if (module) {
                // Start with the module ID
                let modulePayload = `${moduleId}`;

                // Append selected permissions IDs
                if (selectedPermissions[moduleId]) {
                    const permissionIds = Object.keys(selectedPermissions[moduleId])
                        .filter(permissionId => selectedPermissions[moduleId][permissionId])
                        .join(',');
                    modulePayload += `,${permissionIds}`;
                }

                // Include this module's payload
                payload.push(modulePayload);

                // Handle submodules if any are selected
                if (module.sub_modules && module.sub_modules.length > 0) {
                    module.sub_modules.forEach((subModule) => {
                        if (selectedSubmodules.includes(subModule.id)) {
                            let submodulePayload = `${subModule.id}`;

                            // Append selected permissions IDs for the submodule
                            if (selectedPermissions[subModule.id]) {
                                const subPermissionIds = Object.keys(selectedPermissions[subModule.id])
                                    .filter(permissionId => selectedPermissions[subModule.id][permissionId])
                                    .join(',');
                                submodulePayload += `,${subPermissionIds}`;
                            }

                            // Include this submodule's payload
                            payload.push(submodulePayload);
                        }
                    });
                }
            }
        });

        // Join all module and submodule payloads with '|'
        const finalPayload = payload.join('|');
        console.log("finalPayload: ", finalPayload);

        const obj = {
            rid: role,
            moduleidsarray: finalPayload
        }
        const response = await dispatch(handlePostRequest(obj, "/api/Authentication/AddPermission", true, true));
        setLoadingIcon('')
        if (response?.statusCode == 200) {
            setRole('')
            setSelectedModules([]);
            setSelectedSubmodules([]);
            setSelectedPermissions([]);
        }
    };

    //Get All Roles
    const getRolesList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Authentication/getAllRole?rid=${roletypeid || 0}&uid=${usertype_id || 0}&wid=${warehouseid || 0}`, false));
        if (response) {
            setAllRoles(response);
        }
        setIsActive(false);
    };

    //Get All Users
    const getModulesList = async () => {

        setIsActive(true);
        const response = await dispatch(handleGetRequest(`/api/Authentication/Getallmodulebylist?roletype_id=${roletypeid}`, true));
        if (response) {
            setMyApiData(response)
        }
        setIsActive(false);
    };

    useEffect(() => {
        getModulesList()
        getRolesList()
    }, [])

    const handleModuleChange = (moduleId, isSubModule) => {
        setSelectedModules((prevModules) => {
            const isCurrentlySelected = prevModules.includes(moduleId);
            const newSelectedModules = isCurrentlySelected
                ? prevModules.filter(id => id !== moduleId)
                : [...prevModules, moduleId];

            const module = myApiData.find(mod => mod.id === moduleId);
            if (module) {
                const subModuleIds = module.sub_modules?.map(sub => sub.id) || [];
                if (isCurrentlySelected) {
                    // Uncheck all submodules and their permissions
                    setSelectedSubmodules(prevSubmodules =>
                        prevSubmodules.filter(subId => !subModuleIds.includes(subId))
                    );
                    setSelectedPermissions(prevPermissions => {
                        const updatedPermissions = { ...prevPermissions };
                        [moduleId, ...subModuleIds].forEach(id => delete updatedPermissions[id]);
                        return updatedPermissions;
                    });
                } else {
                    // Check all submodules and their permissions
                    setSelectedSubmodules(prevSubmodules => [...new Set([...prevSubmodules, ...subModuleIds])]);
                    setSelectedPermissions(prevPermissions => {
                        const updatedPermissions = { ...prevPermissions };
                        updatedPermissions[moduleId] = module.permissions.reduce((perms, perm) => {
                            perms[perm.permission_id] = true;
                            return perms;
                        }, {});

                        subModuleIds.forEach(subModuleId => {
                            const subModule = module.sub_modules.find(sub => sub.id === subModuleId);
                            updatedPermissions[subModuleId] = subModule.permissions.reduce((perms, perm) => {
                                perms[perm.permission_id] = true;
                                return perms;
                            }, {});
                        });

                        return updatedPermissions;
                    });
                }
            }

            return newSelectedModules;
        });
    };

    const handleSubmoduleChange = (submoduleId) => {
        setSelectedSubmodules((prev) =>
            prev.includes(submoduleId) ? prev.filter(id => id !== submoduleId) : [...prev, submoduleId]
        );
    };

    const getPermissionIdByName = (permissions, name) => {
        const permission = permissions.find(p => p.permissionName === name);
        return permission ? permission.permission_id : null;
    };

    const handlePermissionChange = (moduleId, permissionId, permissionName, isSubmodule = false, parentModuleId = null) => {
        setSelectedPermissions(prev => {
            const newPermissions = { ...prev };
            const targetModuleId = isSubmodule ? parentModuleId : moduleId;
    
            // Toggle the current permission
            newPermissions[targetModuleId] = {
                ...newPermissions[targetModuleId],
                [permissionId]: !newPermissions[targetModuleId]?.[permissionId],
            };
    
            // Update view permission based on manage or create
            const permissions = myApiData.find(mod => mod.id === targetModuleId)?.permissions;
            if (permissions) {
                const viewId = getPermissionIdByName(permissions, 'view');
                const manageId = getPermissionIdByName(permissions, 'manage');
                const createId = getPermissionIdByName(permissions, 'create');
    
                // Check or uncheck the view permission based on manage and create states
                if (permissionName === 'manage' || permissionName === 'create') {
                    if (newPermissions[targetModuleId][manageId] || newPermissions[targetModuleId][createId]) {
                        newPermissions[targetModuleId][viewId] = true; // Automatically check 'view'
                    } else {
                        newPermissions[targetModuleId][viewId] = false; // Uncheck 'view' if both are false
                    }
                }
            }
    
            return newPermissions;
        });
    };
    


    const handleSelectAll = () => {
        if (myApiData) {
            const newSelectedModules = [];
            const newSelectedSubmodules = [];
            const newSelectedPermissions = {};

            myApiData.forEach((module) => {
                // Handle Main Modules
                newSelectedModules.push(module?.id);

                // Handle Submodules
                module.sub_modules.forEach((subModule) => {
                    newSelectedSubmodules.push(subModule?.id);

                    // Initialize permissions object for submodules
                    if (!newSelectedPermissions[subModule?.id]) {
                        newSelectedPermissions[subModule?.id] = {};
                    }

                    // Handle Permissions for Submodules
                    subModule.permissions.forEach((permission) => {
                        newSelectedPermissions[subModule?.id][permission?.permission_id] = true;
                    });
                });

                // Initialize permissions object for main module
                if (!newSelectedPermissions[module.id]) {
                    newSelectedPermissions[module.id] = {};
                }

                // Handle Permissions for Main Module
                module.permissions.forEach((permission) => {
                    newSelectedPermissions[module?.id][permission?.permission_id] = true;
                });
            });

            // Set the selected modules, submodules, and permissions
            setSelectedModules(newSelectedModules);
            setSelectedSubmodules(newSelectedSubmodules);
            setSelectedPermissions(newSelectedPermissions);
        }
    };

    const handleDeSelectAll = () => {
        setSelectedModules([]);
        setSelectedSubmodules([]);
        setSelectedPermissions([]);
    }

    const getPermissionDataById = async (roleId) => {
        try {
            const response = await dispatch(handleGetRequest(`/api/Authentication/GetPermissionbyid?rid=${roleId}`, true));
            const res = response;  // Make sure you are accessing the right property for data.

            if (res && res.length > 0) {
                const newSelectedModules = [];
                const newSelectedSubmodules = [];
                const newSelectedPermissions = {};

                res.forEach(module => {
                    newSelectedModules.push(module.module_ID);

                    // Main module permissions
                    if (!newSelectedPermissions[module.module_ID]) {
                        newSelectedPermissions[module.module_ID] = {};
                    }
                    module.permissions.forEach(permission => {
                        newSelectedPermissions[module.module_ID][permission.permission_id] = true;
                    });

                    // Submodule permissions
                    module.submodules && module.submodules.forEach(subModule => {
                        newSelectedSubmodules.push(subModule.module_ID);

                        if (!newSelectedPermissions[subModule.module_ID]) {
                            newSelectedPermissions[subModule.module_ID] = {};
                        }
                        subModule.permissions.forEach(permission => {
                            newSelectedPermissions[subModule.module_ID][permission.permission_id] = true;
                        });
                    });
                });

                // Update states
                setSelectedModules(newSelectedModules);
                setSelectedSubmodules(newSelectedSubmodules);
                setSelectedPermissions(newSelectedPermissions);
            } else {
                // Reset states if no modules are returned
                setSelectedModules([]);
                setSelectedSubmodules([]);
                setSelectedPermissions({});
            }
        } catch (error) {
            console.error("Error fetching permission data:", error);
        }
    };


    useEffect(() => {
        if (role) {
            getPermissionDataById(role);
        }
    }, [role]);

    return (

        <>
            <div className="card-assign-permissions mt-3">
                <div style={{ overflowX: 'auto' }}>
                    <div class="col-12 assign-header">
                        <div class="col-12 lg:col-4 md:col-12">
                            <div class="input-card flex flex-column p-2">
                                <div className='flex flex-row'>
                                    <label> Role </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown
                                    placeholder='--Select Role--'
                                    id="role"
                                    name="role"
                                    options={allRoles}
                                    optionLabel='name'
                                    optionValue='id'
                                    value={role}
                                    onChange={(e) => setRole(e.value)}
                                    className="p-inputtext-sm"
                                    autoComplete="off"
                                ></Dropdown>
                            </div>
                        </div>
                        <div class="col-12 lg:col-2 md:col-12">
                        </div>
                        <div class="col-12 lg:col-6 assign-header-btn md:col-6">
                            <Button style={{ float: "right" }} type="button" label="Select All" aria-label="Select All" className="selectButton select-btn mr-2 float-right" onClick={handleSelectAll} />
                            <Button style={{ float: "right" }} type="button" label="Deselect All" aria-label="Deselect All" className="deselectButton mr-2 float-right" onClick={handleDeSelectAll} />
                        </div>
                    </div>
                    <table className='col-12 card datatable-responsive'>
                        <thead className='card' style={{ height: "40px", color: "black", fontWeight: 'lighter', padding: "20px 2px" }}>
                            <tr>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Module</th>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Sub Module</th>
                                <th className="customTable-header-resp" style={{ width: '33.33%', textAlign: "left", padding: "18px 30px" }}>Permissions</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#efefef", color: "black" }}>
                            {myApiData.map((module) => (
                                <tr key={module.id}>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        <label className="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={selectedModules.includes(module.id)}
                                                onChange={() => handleModuleChange(module.id, module.isSubModule)}
                                            />
                                            <span className="checkmark">
                                                <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                            </span>
                                            {module.contentName}
                                        </label>
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        {module.isSubModule && selectedModules.includes(module.id) && (
                                            module.sub_modules.map((submodule) => (
                                                <div key={submodule.id}>
                                                    <label className="custom-checkbox mb-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedSubmodules.includes(submodule.id)}
                                                            onChange={() => handleSubmoduleChange(submodule.id)}
                                                        />
                                                        <span className="checkmark">
                                                            <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                        </span>
                                                        {submodule.contentName}
                                                    </label>
                                                </div>
                                            ))
                                        )}
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '22px 25px', textAlign: 'left' }}>
                                        {selectedModules.includes(module.id) && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                    {module.permissions.map((permission) => (
                                                        <label key={permission.permission_id} style={{ marginRight: "10px" }} className="custom-checkbox mb-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedPermissions[module.id]?.[permission.permission_id]}
                                                                onChange={() => handlePermissionChange(module.id, permission.permission_id, permission.permissionName)}
                                                                disabled={permission.permissionName === 'view' && (selectedPermissions[module.id]?.[getPermissionIdByName(module.permissions, 'manage')] || selectedPermissions[module.id]?.[getPermissionIdByName(module.permissions, 'create')])}
                                                            />
                                                            <span className="checkmark">
                                                                <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                            </span>
                                                            {permission.permissionName}
                                                        </label>
                                                    ))}

                                                </div>
                                                {module.sub_modules.filter(submodule => selectedSubmodules.includes(submodule.id)).map((submodule, index, array) => (
                                                    <React.Fragment key={submodule.id}>
                                                        <div className="moduleContainer" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                            {submodule.permissions.map((permission) => (
                                                                <React.Fragment key={permission.permission_id}>
                                                                    <label style={{ marginRight: "10px" }} className="custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedPermissions[submodule.id]?.[permission.permission_id]}
                                                                            onChange={() => handlePermissionChange(submodule.id, permission.permission_id)}
                                                                        />
                                                                        <span className="checkmark">
                                                                            <span className="checkmark-icon"><i className="pi pi-check" style={{ fontWeight: "bold" }}></i></span>
                                                                        </span>
                                                                        {permission.permissionName}
                                                                    </label>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        {index < array.length - 1 && <hr style={{ borderTop: "none", width: "80%", margin: "0.5rem 2.7rem" }} />}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
                <div align="center" className="col-12 text-right">
                    <Button type="submit" disabled={role === '' || selectedModules?.length === 0 ? true : false} iconPos="right" icon={loadingIcon || ""} label="Save" aria-label="Save" onClick={handleSubmit} className="selectModuleButton" />
                </div>
            </div>
        </>
    );
};

export default AssignPermissions