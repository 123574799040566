import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import "./AppTopbar.scss";
import dp from '../src/assets/warehouse-assets/dp.jpeg'
import { baseURL } from "./Config";

export const AppTopbar = (props) => {

    const history = useHistory();

    const user_name = localStorage.getItem("user_name")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")
    const img = localStorage.getItem("img")

    return (
        
        <>
            <div className="layout-topbar">

                <button type="button" className="p-link  layout-menu-button layout-topbar-button ml-1" onClick={props.onToggleMenuClick}>
                    <i className="pi pi-bars" />
                </button>
                <div className="flex flex-column ml-3 text-center topbar-heading">
                    <span style={{ fontWeight: "bolder", fontSize: "21px", letterSpacing: '2px', color: "#5465df" }}>WareHouse <span style={{ color: 'black' }}> MS </span></span>
                </div>

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    <li className="flex" style={{ alignItems: "center" }}>
                        <span className="pi pi-cog mr-3" style={{ fontSize: '2rem', color: 'gray', cursor: 'pointer' }} onClick={() => history.push('/settings')}></span>
                        {/* <span className="pi pi-bell mr-3" style={{ fontSize: '2rem', color: 'gray', cursor: 'pointer' }} onClick={() => history.push('/settings')}></span> */}

                        <span className="ml-2">
                            <img src={img ? `${baseURL}/${img}` : dp} style={{ borderRadius: "50%", width: "42px", height: "42px" }} />
                        </span>

                        <div className="flex flex-column ml-3">
                            <span style={{ color: "black", fontWeight: 'bold' }}> {user_name} </span>
                            <span style={{ color: "gray" }}>{roletypeid == '1' && !warehouseid ? 'Super Admin' : roletypeid == '2' && usertype_id == '1' ? 'System User' : roletypeid == '2' && warehouseid ? 'Admin' : null} </span>
                        </div>
                    </li>
                </ul>

            </div>
        </>
    );
};
