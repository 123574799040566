import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { TreeSelect } from 'primereact/treeselect';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import "./style.scss"
import { handleGetRequest } from '../../services/GetTemplate';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';

const AddEditSubCategory = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const { isEditable, rowData, isView } = location?.state;
    const [allCategories, setAllCategory] = useState([]);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const formik = useFormik({
        initialValues: {
            categoryId: "",
            subCategoryName: ""
        },
        validationSchema: Yup.object().shape({
            categoryId: Yup.mixed().required("Category is required."),
            subCategoryName: Yup.string().required("Sub-category name is required.")
        }),
        onSubmit: async (data) => {
            if (isEditable) {
                console.log(true)
                setSaveBtnLoading(true);

                let obj = {
                    obj_id: rowData?.key,
                    name: data?.subCategoryName,
                    parentid: data?.categoryId,
                    isActive: 1,
                    description: null
                }

                const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateSubCategory", true, true));
                console.log('response', response)
                if (response?.statusCode == 200) {
                    history.push('./category')
                    setSaveBtnLoading(false);
                }
            } else {

                setSaveBtnLoading(true);
                let obj = {
                    parent_id: data?.categoryId,
                    name: data?.subCategoryName,
                    warehouseId: warehouseid,
                    description: null
                }
                const response = await dispatch(handlePostRequest(obj, "/api/Setups/AddSubCategory", true, true));
                console.log('response', response)
                if (response?.statusCode == 200) {
                    history.push('./category')
                    setSaveBtnLoading(false);
                }

                setSaveBtnLoading(false);
            }
        }
    });





    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };



    const ResetForm = () => {
        formik.setValues({
            categoryId: "",
            subCategoryName: ""
        })
    }

    // Get User By Id
    const getSubCategoryById = async (rowData) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Setups/GetSubCategoryByid?obj_id=${rowData?.key}`, true));
        const keyData = response[0]
        console.log(keyData)
        if (keyData) {
            formik.setFieldValue('subCategoryName', keyData?.obj_Name)
            formik.setFieldValue('categoryId', keyData?.parent_id)
        }
        setIsActive(false)
    };

    const getCategoryList = async () => {
        setIsActive(true);
        const response = await dispatch(
            handleGetRequest(`/api/Setups/GetAllSubCategories?warehouse_id=${warehouseid || 0}`, true)
        );
        if (response) {
            // const modifiedCategories = allCategories.flatMap(category => category.children);
            setAllCategory(response);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getCategoryList()
        if (isEditable, rowData) {
            getSubCategoryById(rowData);
        }
    }, [isEditable, rowData]);

    //Disable Form while Viewingg
    const formStyle = {
        pointerEvents: isView ? 'none' : 'auto',
        opacity: isView ? 0.7 : 1,
    };


    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}>{isEditable && isView ? 'View Sub Category' : isEditable && isView === false ? 'Edit Sub Category' : 'Add New Sub Category'}</h4>
                            <h6 style={{ color: 'gray' }}>{isView ? '' : 'Add Details'} </h6>
                        </div>

                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit} style={formStyle}>
                            <div className="card-form">
                                <div className="card-nested-form">
                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Category </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <TreeSelect
                                                    placeholder='Select Category'
                                                    id="categoryId"
                                                    name="categoryId"
                                                    filter
                                                    options={allCategories}
                                                    value={formik.values?.categoryId}
                                                    onChange={formik.handleChange}
                                                    className="p-inputtext-sm text-sm"
                                                ></TreeSelect>
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("categoryId")}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex-column p-3 ">
                                                <div className='flex-row'>
                                                    <label> Sub-Category Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm mb-1" id="subCategoryName" name="subCategoryName" placeholder='Enter Sub Category Name' value={formik.values.subCategoryName} onChange={formik.handleChange} type="text" />
                                                <div className='ml-2'>
                                                    {getFormErrorMessage("subCategoryName")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {isView ?
                                        null
                                        :
                                        <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                            <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                            <Button className="Save-Button w-max-content ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};

export default AddEditSubCategory;
