import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import editIcon from "../../assets/warehouse-assets/colored/edit.png"
import deleteIcon from "../../assets/warehouse-assets/colored/dlet.png"
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ReportingDetails = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isActive, setIsActive] = useState(false)
    const [userdataId, setUserDataId] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        return (
            <div className="">
                <Button className='edit-btn ml-3' onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className='edit-btn mr-3' onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Edit" />
                </Button>
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message: 'Are you sure you want to Delete this User ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteUser(rowData),
            reject: null
        });
    }

    const handleDeleteUser = async (rowData) => {

        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/user/delete/${rowData?._id}`, true, true));
        if (response?.status === 200) {
            await getUserList()
        }

        else if (response?.status === 403) {
            window.localStorage.clear();
            history.push("/")
            toast.info("Please Login again")
        }

        setIsActive(false);
    }

    const nameTemplate = (rowData) => {

        const result = `${rowData.first_name} ${rowData?.last_name}`
        return (
            <>
                {result}
            </>
        )
    }

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserDataId(rowData?._id);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    //Get All Users
    const getUserList = async () => {

        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/user/getAll", false));
        if (response) {
            setAllUsers(response?.Data);
        }
        setIsActive(false);
    };

    const allUsersStatic = [
        {
            warehouse: 'XYZ', inventory: 'Abcd', rfid: '0f73', sku: "938d83", quantity: '10', value: "20", vendor: 'zzz'
        },
        {
            warehouse: 'XYZ', inventory: 'Abcd', rfid: '0f73', sku: "938d83", quantity: '10', value: "20", vendor: 'zzz'
        },
        {
            warehouse: 'XYZ', inventory: 'Abcd', rfid: '0f73', sku: "938d83", quantity: '10', value: "20", vendor: 'zzz'
        },
        {
            warehouse: 'XYZ', inventory: 'Abcd', rfid: '0f73', sku: "938d83", quantity: '10', value: "20", vendor: 'zzz'
        },
        {
            warehouse: 'XYZ', inventory: 'Abcd', rfid: '0f73', sku: "938d83", quantity: '10', value: "20", vendor: 'zzz'
        },

    ]

    useEffect(() => {
        // getUserList()
    }, [])

    return (

        <>
            <div className="flex flex-row custom-alignment justify-content-end">
                <span className="p-input-icon-left mr-3 mb-1">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        className="search-input"
                    />
                </span>

                <div className="">
                    <Button
                        label="Download"
                        className="Add__New-Button ml-3"
                        icon="pi pi-download"
                        onClick=''
                    />
                </div>
                <div className="ml-3">
                    <Button className='Back-Button' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                    </Button>
                </div>
            </div>

            <div className="card mt-3">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle
                        height="120"
                        width="120"
                        color="#0E828A"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />}

                    // text='Loading your content...'
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgb(38 41 51 / 78%)',
                        })
                    }}
                >

                    <DataTable
                        responsive={true}
                        filters={filters}
                        globalFilterFields={[
                            "first_name",
                            "last_name",
                            "email",
                            "cnic",
                            "gender",
                            "designation",
                            "contact",
                            "department.departmentName"
                        ]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsersStatic}
                        emptyMessage="No records found"
                        paginatorClassName="custom-paginator"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column sortable field="warehouse" header="Product ID"></Column>
                        <Column sortable field="inventory" header="Inventory"></Column>
                        <Column sortable field="rfid" header="RFID"></Column>
                        <Column sortable field="sku" header="SKU"></Column>
                        <Column sortable field="quantity" header="Quantity"></Column>
                        <Column sortable field="value" header="Threshold Value"></Column>
                        <Column sortable field="vendor" header="Vendor"></Column>
                        {/* <Column header="Action" body={actionTemplate} /> */}
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ReportingDetails