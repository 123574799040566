import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../services/PostTemplate';
import { handlePutRequest } from '../../services/PutTemplate';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { handleGetRequest } from '../../services/GetTemplate';

const AddEditWarehouse = () => {

    const location = useLocation()
    const { isEditable, rowData } = location?.state;
    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        warehouse_id: Yup.mixed().required("This field is required."),
        warehouse_name: Yup.mixed().required("This field is required."),
        warehouse_poc: Yup.mixed().required("This field is required."),
        // contact_no: Yup.mixed().required("This field is required."),
        // address: Yup.mixed().required("This field is required."),
        // location: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            warehouse_id: "",
            warehouse_name: "",
            warehouse_poc: "",
            contact_no: "",
            address: "",
            location: "",
        },

        onSubmit: async (data) => {

            if (isEditable) {
                setSaveBtnLoading(true);

                let obj = {
                    obj_id: rowData?.obj_id,
                    name: formik.values.warehouse_name,
                    warehouse_id: formik.values.warehouse_id,
                    warehouse_poc: formik.values.warehouse_poc,
                    contact_no: formik.values.contact_no,
                    address: formik.values.address,
                    location: formik.values.location,
                }

                const response = await dispatch(handlePutRequest(obj, "/api/Setups/UpdateWarehouse", true, true));
                if (response?.statusCode == 200) {
                    history.push('./warehouse')
                    setSaveBtnLoading(false);
                }
            }
            else {
                setSaveBtnLoading(true);

                let obj = {
                    name: formik.values.warehouse_name,
                    warehouse_id: formik.values.warehouse_id,
                    warehouse_poc: formik.values.warehouse_poc,
                    contact_no: formik.values.contact_no,
                    address: formik.values.address,
                    location: formik.values.location,
                }

                const response = await dispatch(handlePostRequest(obj, "/api/Setups/AddWarehouse", true, true));
                if (response?.statusCode == 200) {
                    history.push('./warehouse')
                    setSaveBtnLoading(false);
                }

                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const ResetForm = () => {
        formik.setFieldValue('warehouse_id', '')
        formik.setFieldValue('warehouse_name', '')
        formik.setFieldValue('warehouse_poc', '')
        formik.setFieldValue('contact_no', '')
        formik.setFieldValue('location', '')
        formik.setFieldValue('address', '')
    }


    //Get WH By Id
    const getWHById = async (rowData) => {
        setIsActive(true)
        const response = await dispatch(handleGetRequest(`/api/Setups/GetWarehouseByid?obj_id=${rowData?.obj_id}`, false));
        const keyData = response[0]
        if (keyData) {
            formik.setFieldValue('warehouse_id', keyData?.warehouse_id)
            formik.setFieldValue('warehouse_name', keyData?.obj_Name)
            formik.setFieldValue('warehouse_poc', keyData?.warehouse_poc)
            formik.setFieldValue('contact_no', keyData?.contact_no)
            formik.setFieldValue('location', keyData?.location)
            formik.setFieldValue('address', keyData?.address)
        }
        setIsActive(false)
    };

    useEffect(() => {
        if (isEditable, rowData) {
            getWHById(rowData);
        }
    }, [isEditable, rowData]);


    // Generate WH Id
    const handleGenerateWHId = () => {
        const newWHId = generateRandomWHId();
        formik.setFieldValue('warehouse_id', newWHId);
    };

    const generateRandomWHId = () => {
        const prefix = "wh-";
        const length = 4;
        const charset = "0123456789";
        let userId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            userId += charset[randomIndex];
        }

        return prefix + userId;
    };

    return (
        <>


            <LoadingOverlay
                active={isActive}
                spinner
                text='Loading your content...'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgb(38 41 51 / 78%)',
                        width: '107.9%',
                        height: '125%',
                        top: '-27px',
                        left: '-35px'
                    })
                }}
            >

                <div className='main-form-card'>
                    <div className='form-header flex flex-row justify-content-between'>
                        <div className='form-header-content'>
                            <h4 style={{ fontWeight: '600', letterSpacing: '1px', color: 'black' }}> Add New Warehouse</h4>
                            <h6 style={{ color: 'gray' }}> Add Details</h6>
                        </div>
                        <div className='mt-4 mr-3'>
                            <Button className='Back-Button mr-3' icon="pi pi-arrow-left" label='Go Back' onClick={() => history.goBack()}>
                            </Button>
                        </div>
                    </div>
                    <div className='form-div'>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="card-form">
                                <div className="card-nested-form">

                                    <div class="grid mt-2">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse Id </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <InputText
                                                        disabled
                                                        type='text'
                                                        placeholder='Auto Generate WH Id'
                                                        id='warehouse_id'
                                                        name="warehouse_id"
                                                        value={formik.values.warehouse_id}
                                                        onChange={formik.handleChange}
                                                        className="p-inputtext-sm"
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        tooltip='Auto Generate Warehouse Id'
                                                        tooltipOptions={{ position: "top" }}
                                                        type="button"
                                                        icon="pi pi-refresh"
                                                        className='auto-generate-button'
                                                        onClick={handleGenerateWHId}
                                                        style={{
                                                            background: '#8d7ad5',
                                                            borderColor: "#8d7ad5",
                                                            position: 'absolute',
                                                            top: '30%',
                                                            right: '8px',
                                                            transform: 'translateY(-50%)',
                                                        }}
                                                    />
                                                </div>
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_id")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse Name </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText className="p-inputtext-sm" maxLength={30}
                                                    keyfilter={/^[a-zA-Z\s]*$/} id="warehouse_name" name="warehouse_name" placeholder='Enter Warehouse Name' value={formik.values.warehouse_name} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_name")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Warehouse POC </label>
                                                    <span className="Label__Required">*</span>
                                                </div>
                                                <InputText maxLength={15} minLength={10} keyfilter={/^[0-9.!@#$%^&*]+$/} className="p-inputtext-sm" id="warehouse_poc" name="warehouse_poc" placeholder='Enter POC' value={formik.values.warehouse_poc} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("warehouse_poc")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid">
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Contact No. </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <InputText maxLength={15} minLength={10} keyfilter={/^[0-9.!@#$%^&*]+$/} className="p-inputtext-sm" id="contact_no" name="contact_no" placeholder='Enter Warehouse Contact No.' value={formik.values.contact_no} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("contact_no")}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Address </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <InputText className="p-inputtext-sm" id="address" name="address" placeholder='Enter Warehouse Addres' value={formik.values.address} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("address")}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-12 lg:col-4 md:col-4">
                                            <div class="input-card flex flex-column p-3">
                                                <div className='flex-row'>
                                                    <label> Location </label>
                                                    {/* <span className="Label__Required">*</span> */}
                                                </div>
                                                <InputText className="p-inputtext-sm" id="location" name="location" placeholder='Enter Warehouse Location' value={formik.values.location} onChange={formik.handleChange} type="text" />
                                                <span className='ml-2'>
                                                    {getFormErrorMessage("location")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 d-flex flex-row text-center mt-4 pb-2'>
                                        <Button className="Cancel-Button" label="Reset" type="button" onClick={ResetForm} />
                                        <Button className="Save-Button ml-2" label={isEditable ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </LoadingOverlay>
        </>
    )
}

export default AddEditWarehouse