import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";

const ManageCategory = () => {

    const user_id = localStorage.getItem("user_id")
    const warehouseid = localStorage.getItem("warehouseid")
    const roletypeid = localStorage.getItem("roletypeid")
    const usertype_id = localStorage.getItem("usertype_id")

    const history = useHistory();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);
    const [allCategories, setAllCategory] = useState([]);
    const [acceptButtonClass, setAcceptButtonClass] = useState("p-button-danger");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [expandedRows, setExpandedRows] = useState(null);
    const [expandedChildRows, setExpandedChildRows] = useState(null);
    const [filteredCategory, setFilteredCategory] = useState(null);
    const [filteredAllCategories, setFilteredAllCategories] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [parentId, setParentId] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {

        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'View',
                command: () => handleViewCategory(rowData),
            });
        } else {
            items.push(
                {
                    label: 'Edit',
                    command: () => handleEditCategory(rowData),
                },
                {
                    label: rowData?.activeStatus == "Active" ? "Deactivate" : "Activate",
                    command: () => confirm(rowData),
                }
            );
        }


        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm = (rowData) => {
        confirmDialog({
            message:
                rowData?.activeStatus === "Active"
                    ? "Are you sure you want to deactivate this Category?"
                    : "Are you sure you want to activate this Category?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: acceptButtonClass,
            acceptLabel: "Yes",
            accept: () => {
                if (rowData?.activeStatus === "Active") {
                    handleDeactivateCategory(rowData);
                } else {
                    handleActivateCategory(rowData);
                }
            },
            reject: () => null,
        });
    };

    const handleDeactivateCategory = async (rowData) => {
        const obj = {
            obj_id: rowData?.key,
            name: rowData?.label,
            isActive: 0,
            description: null,

        };
        setIsActive(true);


        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateCategory`, true, true));
        if (response?.statusCode == 200) {
            await getCategoryList();
        }
        setIsActive(false);
    };

    const handleActivateCategory = async (rowData) => {
        const obj = {
            obj_id: rowData?.key,
            name: rowData?.label,
            isActive: 1,
            description: null,
        };
        setIsActive(true);
        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateCategory`, true, true));
        if (response?.statusCode == 200) {
            await getCategoryList();
        }
        setIsActive(false);
    }

    const actionChildTemplate = (rowData) => {

        const items = [];

        if ((roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid)) {
            items.push({
                label: 'View',
                command: () => handleViewSubCategory(rowData),
            });
        } else {
            items.push(
                {
                    label: 'Edit',
                    command: () => handleEditSubCategory(rowData),
                },
                {
                    label: rowData?.activeStatus == 'Active' ? "Deactivate" : "Activate",
                    command: () => confirm2(rowData),
                }
            );
        }

        return (
            <div className="">
                <SplitButton label="" model={items} />
            </div>
        );
    };

    const confirm2 = (rowData) => {
        confirmDialog({
            message:
                rowData?.activeStatus === "Active"
                    ? "Are you sure you want to deactivate this Sub Category?"
                    : "Are you sure you want to activate this Sub Category?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: acceptButtonClass,
            acceptLabel: "Yes",
            accept: () => {
                if (rowData?.activeStatus === "Active") {
                    handleDeactivateSubCategory(rowData);
                } else {
                    handleActivateSubCategory(rowData);
                }
            },
            reject: () => null,
        });
    };

    const handleDeactivateSubCategory = async (rowData) => {
        const obj = {
            obj_id: rowData?.key,
            name: rowData?.label,
            isActive: 0,
            description: null,
            parentid: parentId,

        };
        setIsActive(true);


        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateSubCategory`, true, true));
        if (response?.statusCode == 200) {
            await getCategoryList();
        }
        setIsActive(false);
    };

    const handleActivateSubCategory = async (rowData) => {
        const obj = {
            obj_id: rowData?.key,
            name: rowData?.label,
            isActive: 1,
            description: null,
            parentid: parentId,
        };
        setIsActive(true);
        const response = await dispatch(handlePutRequest(obj, `/api/Setups/UpdateSubCategory`, true, true));
        if (response?.statusCode == 200) {
            await getCategoryList();
        }
        setIsActive(false);
    }

    const handleEditCategory = (rowData) => {
        const item = true;
        const view = false;
        history.push({
            pathname: "./addeditcategory",
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            },
        });
    };

    const handleViewCategory = (rowData) => {
        const item = true;
        const view = true;
        history.push({
            pathname: "./addeditcategory",
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            },
        });
    };

    const handleSubCategory = () => {
        const item = false;
        history.push({
            pathname: "./addeditsubcategory",
            state: {
                isEditable: item,
            },
        });
    };

    const handleCategory = () => {
        const item = false;
        history.push({
            pathname: "./addeditcategory",
            state: {
                isEditable: item,
            },
        });
    };



    const handleEditSubCategory = (rowData) => {
        const view = false;

        const item = true;
        history.push({
            pathname: "./addeditsubcategory",
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            },
        });
    };


    const handleViewSubCategory = (rowData) => {
        const view = true;
        const item = true;
        history.push({
            pathname: "./addeditsubcategory",
            state: {
                isEditable: item,
                rowData: rowData,
                isView: view,
            },
        });
    };


    //Get All Category List
    const getCategoryList = async () => {
        setIsActive(true);
        const response = await dispatch(
            handleGetRequest(`/api/Setups/GetAllSubCategories?warehouse_id=${warehouseid || 0}`, true)
        );
        if (response) {
            setAllCategory(response);
            setFilteredAllCategories(response);
        }
        setIsActive(false);
    };

    // const getFilteredCategoryList = async () => {
    //     setIsActive(true);
    //     const response = await dispatch(
    //         handleGetRequest(`/api/Setups/GetAllCategories?warehouse_id=${warehouseid || 0}`, true)
    //     );
    //     if (response) {
    //         setFilteredAllCategories(response);
    //     }
    //     setIsActive(false);
    // };

    useEffect(() => {
        getCategoryList()
        // getFilteredCategoryList()
    }, [])

    useEffect(() => {
        if (filteredCategory || filteredAllCategories) {
            if (filteredCategory && filteredCategory !== undefined) {
                setAllCategory(filteredAllCategories?.filter(item => item?.key == filteredCategory))
            }
            else {
                setAllCategory(filteredAllCategories)
            }
        }

    }, [filteredCategory, filteredAllCategories])

    const allowExpansion = (rowData) => {
        const isExpanded = expandedRows && expandedRows[rowData.key];
        return (
            <>
                {
                    rowData?.children.length > 0 ?
                        <i className={`pi pi-chevron-${isExpanded ? 'down' : 'right'} cursor-pointer`}
                            onClick={() => toggleRowExpansion(rowData)}
                        /> :
                        <></>
                }
            </>
        );
    };

    const toggleRowExpansion = (rowData) => {
        console.log('toggleRowExpansion', rowData)
        setParentId(rowData?.key)
        let _expandedRows = { ...expandedRows };

        if (_expandedRows[rowData.key]) {
            delete _expandedRows[rowData.key];
        } else {
            _expandedRows[rowData.key] = true;
        }

        setExpandedRows(_expandedRows);
    };

    const allowChildExpansion = (rowData) => {
        const isExpanded = expandedChildRows && expandedChildRows[rowData.key];
        return (
            <>
                {
                    rowData?.children.length > 0 ?
                        <i className={`pi pi-chevron-${isExpanded ? 'down' : 'right'} cursor-pointer`}
                            onClick={() => toggleChildRowExpansion(rowData)}
                        /> : <></>
                }
            </>

        );
    };

    const toggleChildRowExpansion = (rowData) => {
        let _expandedChildRows = { ...expandedChildRows };

        if (_expandedChildRows[rowData.key]) {
            delete _expandedChildRows[rowData.key];
        } else {
            _expandedChildRows[rowData.key] = true;
        }

        setExpandedChildRows(_expandedChildRows);
    };

    const rowExpansionTemplate = (data) => <CategoryTable nodes={data} />;

    const CategoryTable = ({ nodes }) => {
        return (
            <div className="pt-3">
                <h6 className="text-left font-semibold">Subcategory of {nodes.label}</h6>
                <DataTable
                    stripedRows
                    value={nodes.children}
                    expandedRows={expandedChildRows}
                    onRowToggle={(e) => setExpandedChildRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    responsiveLayout="scroll"
                    dataKey="key"
                >
                    <Column body={allowChildExpansion} style={{ width: "3em" }} />
                    <Column field="key" header="SubCategory ID" />
                    <Column field="label" header="SubCategory Name" sortable />
                    <Column
                        sortable
                        field="status"
                        body={(rowData) => (
                            <Tag severity={rowData?.activeStatus == 'Active' ? "success" : "danger"} value={rowData?.activeStatus}></Tag>
                        )}
                        header="Status"
                    />
                    <Column sortable field='createddatetime' body={(rowData) => rowData?.createddatetime || 'N/A'} header="Date & Time"></Column>
                    <Column header="Action" body={actionChildTemplate} />
                </DataTable>
            </div>
        );
    };


    useEffect(() => {

        const myPermissions = JSON.parse(localStorage.getItem("permissions"))
        const allPermModules = myPermissions?.find(module => module?.routepath?.includes("/Setups"));
        console.log('allPermModules', allPermModules)
        if (allPermModules) {
            const result = allPermModules?.submodules?.find(item => item?.routepath == "/category")
            setPermissions(result?.permissions?.map(item => item?.permission_id) || []);
        }

    }, []);


    return (
        <>
            <div className="flex flex-row custom-alignment justify-content-end">
                <span className="p-input-icon-left mr-2 resp-margin">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        className="search-input2"
                    />
                </span>
                <div className="resp-margin">
                    <Dropdown
                        showClear
                        options={filteredAllCategories}
                        optionValue="key"
                        optionLabel="label"
                        value={filteredCategory}
                        onChange={(e) => setFilteredCategory(e.value)}
                        className="dropdown-filter ml-2 mr-1"
                        icon="pi pi-plus"
                        placeholder="--Select Category--"
                    />
                </div>
                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                    null
                    :
                    <div className="resp-margin">
                        <Button
                            label="Assign Sub Category"
                            className="Add__New-Button ml-2"
                            onClick={handleSubCategory}
                        />
                    </div>
                }
                {(roletypeid == 1) || (roletypeid == 2 && usertype_id == 1 && !warehouseid) ?
                    null
                    :
                    permissions.includes(2) ?
                        <div className="">
                            <Button
                                label="Add New"
                                className="Add__New-Button ml-2"
                                icon="pi pi-plus"
                                onClick={handleCategory}
                            />
                        </div>
                        :
                        null
                }
            </div>
            <div className="card mt-3">
                <LoadingOverlay
                    active={isActive}
                    spinner={
                        <Triangle
                            height="120"
                            width="120"
                            color="#755fc8"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    }
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        value={allCategories}
                        expandedRows={expandedRows}
                        globalFilter={globalFilterValue}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        dataKey="key"
                        tableStyle={{ minWidth: "60rem" }}
                        rows={20}
                        paginatorClassName="custom-paginator"
                    >
                        <Column body={allowExpansion} style={{ width: "3em" }} />
                        <Column field="key" header="Category ID" />
                        <Column field="label" header="Category Name" sortable />
                        <Column
                            sortable
                            field="status"
                            body={(rowData) => (
                                <Tag severity={rowData?.activeStatus == 'Active' ? "success" : "danger"} value={rowData?.activeStatus}></Tag>
                            )}
                            header="Status"
                        />
                        <Column sortable field='createddatetime' body={(rowData) => rowData?.createddatetime || 'N/A'} header="Date & Time"></Column>
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default ManageCategory;
